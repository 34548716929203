import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./components/shared/Layout";

import Register from "./pages/Register";
import Users from "./pages/Users";
import Projects from "./pages/Projects";
import EditBuilds from "./pages/EditBuilds";
import Builds from "./pages/Builds";
import HomeLayout from "./home/shared/HomeLayout";
import ProtectedRoute from "./home/shared/ProtectedRoute";

import Specify from "./home/Specify";
import Build from "./home/Build";
import ReportDownloader from "./home/ReportDownloader";
import Login from "./pages/Login";
import Confirm from "./pages/Confirm";
import Project from "./pages/Project";
import Logout from "./pages/Logout";
import EditBuild from "./pages/EditBuild";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import HowToUse from "./pages/HowToUse";

function App() {
  const isLoggedIn = () => {
    return (
      localStorage.getItem("isAuthenticated") &&
      localStorage.getItem("isAuthenticated") == "true"
    );
  };

  const isAdmin = () => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      return user.role == "ADMIN";
    }
    return false;
  };

  const isTM = () => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      return user.role == "TEAM_MEMBER";
    }
    return false;
  };
  const isCustomer = () => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      return user.role == "CUSTOMER";
    }
    return false;
  };

  return (
    <Router>
      <Routes>
        {isAdmin() ? (
          <>
            {" "}
            <Route path="/" element={<Layout />}>
              <Route index element={<Users />} />
              <Route path="projects" element={<Projects />} />
              <Route path="/" element={<Navigate to="/projects" replace />} />
              <Route index path="projects" element={<Projects />} />
              <Route path="builds" element={<Builds />} />
              <Route path="edit-builds" element={<EditBuilds />} />{" "}
            </Route>
          </>
        ) : null}
        {isTM() ? (
          <>
            {" "}
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Navigate to="/projects" replace />} />
              <Route index path="projects" element={<Projects />} />
              <Route index path="builds" element={<Builds />} />
            </Route>
          </>
        ) : null}
        {isCustomer() ? (
          <>
            {" "}
            <Route path="/" element={<Layout />}>
              <Route path="builds" element={<Builds />} />
            </Route>
          </>
        ) : null}

        {/* {{isLoggedIn() ? <>(<Route path="/home" element={<HomeLayout />}>
                    <Route index element={<Specify />} /> 
                    <Route path='build' element={<Build />} /> 
                    <Route path='report' element={<ReportDownloader />} /> 
                </Route>) : (<Navigate to="/login" replace />) </>} */}

        {
          isLoggedIn() ? (
            <>
              {" "}
              <Route path="/home" element={<ProtectedRoute />}>
                <Route element={<HomeLayout />}>
                  <Route index element={<Specify />} />
                  <Route path="build" element={<Build />} />
                  <Route path="report" element={<ReportDownloader />} />
                </Route>
              </Route>
            </>
          ) :
          <Route path="*" element={<Navigate to="/login" replace />} />
        }

        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/confirm" element={<Confirm />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/how-to-use" element={<HowToUse />} />
        <Route path="/project" element={<Project />} />
        <Route path="/view-build" element={<EditBuild />} />
      </Routes>
    </Router>
  );
}

export default App;
