import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CategoriesTable from "../components/CategoriesTable";
import CategoriesTableDragable from "../components/CategoriesTableDragable";
import CreateCategoryModal from "../components/CreateCategoryModal";
import ProductGroupTable from "../components/ProductGroupTable";
import ConfirmationDialog from "../components/shared/ConfirmationDialog";
import EditProductGroupModal from "../components/EditProductGroupModal";
import LoadingSpinner from "./LoadingSpiner";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteCategory,
  deleteSubCategory,
  editCategory,
  editSubCategory,
  fetchCategoriesAndSubcategories,
  saveCategory,
  saveSubcategory,
  toggleCategory,
  toggleSubCategory,
} from "../lib/api/CategoriesHandler";
import {
  editProductGroups,
  fetchProductGroupsAndProducts,
} from "../lib/api/ProductGroupHanlder";

const EditBuilds = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProductGroup, setSelectedProductGroup] = useState(null);
  const [selectedParentCategory, setSelectedParentCategory] = useState({});
  const [productGroup, setProductGroup] = useState([]);
  const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(false);
  const [removeDialogIsOpen, setRemoveDialogIsOpen] = useState(false);
  const [productGroupModalIsOpen, setProductGroupModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pgName, setpgName] = useState();
  const [role, setRole] = useState("ADMIN");

  const openCategoryModal = () => setCategoryModalIsOpen(true);
  const closeCategoryModal = () => {
    setSelectedParentCategory({});
    setSelectedCategory({});
    setCategoryModalIsOpen(false);
  };

  const openProductGroupModal = () => setProductGroupModalIsOpen(true);
  const closeProductGroupModal = () => {
    setSelectedProductGroup(null);
    setProductGroupModalIsOpen(false);
  };

  const openSubCategoryModal = (name) => {
    setSelectedParentCategory(name);
    setCategoryModalIsOpen(true);
  };

  const showRemoveDialog = () => setRemoveDialogIsOpen(true);
  const hideRemoveDialog = () => {
    setSelectedParentCategory({});
    setSelectedCategory({});
    setRemoveDialogIsOpen(false);
  };
  const handleEditCategoryModal = (category) => {
    setSelectedCategory(category);
    openCategoryModal();
  };

  const handleEditProductGroupModal = (productGroupName) => {
    //   console.log("pname", productGroupName);
    setSelectedProductGroup(productGroupName);
    openProductGroupModal();
  };

  const handleEditSubCategoryModal = (category, selected) => {
    setSelectedParentCategory(selected);
    setSelectedCategory(category);
    openCategoryModal();
  };
  const handleAddCategory = async (newCategory) => {
    setLoading(true);
    try {
      const categoryIndex = categories.findIndex((cat) => {
        if (selectedParentCategory) {
          return cat.name === selectedParentCategory;
        }
        return cat.name === newCategory.name;
      });

      const productGroupIndex = productGroup.findIndex(
        (pGroup) => pGroup.name === newCategory.productGroup
      );

      if (!selectedParentCategory && categoryIndex !== -1) {
        toast.error("Category already exists");
      } else {
        const updatedCategories = [...categories];
        if (
          selectedParentCategory &&
          Object.keys(selectedParentCategory).length > 0
        ) {
          if (productGroupIndex !== -1) {
            toast.error("Product Group already exists");
            return;
          }
          const parentCategoryId = categories.find(
            (x) => x.name === selectedParentCategory
          )?.id;

          const saved = await saveSubcategory({
            name: newCategory.name,
            productGroup: newCategory.productGroup,
            text: newCategory.text,
            image: newCategory.image,
            categoryId: parentCategoryId,
          });
          if (!saved.error) {
            updatedCategories[categoryIndex].subcategories.push({
              name: newCategory.name,
              productGroup: newCategory.productGroup,
              text: newCategory.text,
              image: newCategory.image,
            });
            setProductGroup((prev) => [
              ...prev,
              { name: newCategory.productGroup, products: {} },
            ]);
          } else {
            toast.error("Error while creating subcategory");
          }
        } else {
          const savedCategory = await saveCategory({ name: newCategory.name });
          if (!savedCategory.error) {
            updatedCategories.push({ name: newCategory.name });
          } else {
            toast.error("Error while creating category");
          }
        }
        setCategories(updatedCategories);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while adding category");
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const handleEditCategory = async (newCategory) => {
    setLoading(true);
    try {
      let updatedCategories = [];
      if (Object.keys(selectedParentCategory).length === 0) {
        await editCategory(
          categories.find((x) => x.name === newCategory.oldName)?.id,
          newCategory.name
        );
        updatedCategories = categories.map((cat) => {
          if (cat.name === newCategory.oldName) {
            return { ...cat, ...newCategory };
          }
          return cat;
        });
      } else {
        const parentCategory = categories.find(
          (x) => x.name === selectedParentCategory
        );
        const subCat = parentCategory.subcategories.find(
          (x) => x.name === newCategory.oldName
        ).id;
        const edited = await editSubCategory(subCat, {
          name: newCategory.name,
          productGroup: newCategory.productGroup,
          text: newCategory.text,
          image: newCategory.image,
          categoryId: parentCategory.id,
        });
        if (!edited.error) {
          updatedCategories = categories.map((cat) => {
            if (cat.name === selectedParentCategory) {
              const index = cat.subcategories.findIndex(
                (x) => x.name === newCategory.oldName
              );
              if (index !== -1) {
                cat.subcategories[index].name = newCategory.name;
                cat.subcategories[index].text = newCategory.text;
                cat.subcategories[index].productGroup =
                  newCategory.productGroup;
                cat.subcategories[index].image = newCategory.image;
              }
              return cat;
            }
            return cat;
          });
        } else {
          toast.error(edited.error);
        }
      }
      setCategories(updatedCategories);
      setSelectedParentCategory({});
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while editing category");
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const handleEditProductGroup = async (newName) => {
    setLoading(true);
    try {
      if (!selectedProductGroup) {
        toast.error("No group selected");
      } else {
        const edited = await editProductGroups(
          productGroup.find((x) => x.name === selectedProductGroup).id,
          { name: newName }
        );
        if (edited.error) {
          toast.error(edited.error);
          return;
        }
        const updatedGroups = productGroup;
        const groupIndex = productGroup.findIndex(
          (prod) => prod.name === selectedProductGroup
        );
        if (groupIndex !== -1) {
          updatedGroups[groupIndex].name = newName;
        }

        const updatedCategories = categories.map((cat) => {
          const index = cat.subcategories.findIndex(
            (subcat) => subcat.productGroup === selectedProductGroup
          );
          if (index !== -1) {
            cat.subcategories[index].productGroup = newName;
            return cat;
          }
          return cat;
        });

        setProductGroup(updatedGroups);
        setCategories(updatedCategories);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while editing product group");
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const handleRemoveCategory = async () => {
    setLoading(true);
    try {
      if (selectedCategory) {
        let updatedCategories = categories;
        if (Object.keys(selectedParentCategory).length === 0) {
          const deleted = await deleteCategory(
            categories.find(
              (category) => category.name === selectedCategory.name
            )?.id
          );
          if (deleted.error) {
            toast.error(deleted.error);
          } else {
            updatedCategories = categories.filter(
              (category) => category.name !== selectedCategory.name
            );
          }
        } else {
          const index = categories.findIndex(
            (cat) => cat.name === selectedParentCategory
          );
          if (index !== -1) {
            const deleteSub = await deleteSubCategory(
              categories[index].subcategories.find(
                (subcat) => subcat.name === selectedCategory.name
              ).id
            );
            if (deleteSub.error) {
              toast.error(deleteSub.error);
            } else {
              updatedCategories[index].subcategories = updatedCategories[
                index
              ].subcategories.filter(
                (subcat) => subcat.name !== selectedCategory.name
              );
            }
          }
        }
        setCategories(updatedCategories);
      } else {
        toast.error("Administrators cannot be removed");
      }
      setSelectedCategory(null);
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while removing category");
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const handleHideCategory = async (category, parentCategory) => {
    setLoading(true);
    try {
      if (category) {
        let categoryIndex = -1;
        const updatedCategories = [...categories];
        if (parentCategory) {
          const parentIndex = categories.findIndex(
            (cat) => cat.name === parentCategory
          );
          categoryIndex =
            parentIndex !== -1
              ? updatedCategories[parentIndex].subcategories.findIndex(
                  (subcat) => subcat.name === category.name
                )
              : -1;
          if (categoryIndex !== -1) {
            const toggleSub = await toggleSubCategory(
              categories[parentIndex].subcategories[categoryIndex].id
            );
            if (toggleSub.error) {
              toast.error(toggleSub.error);
            } else {
              updatedCategories[parentIndex].subcategories[
                categoryIndex
              ].hidden =
                !updatedCategories[parentIndex].subcategories[categoryIndex]
                  .hidden;
            }
          }
        } else {
          categoryIndex = categories.findIndex(
            (cat) => cat.name === category.name
          );
          if (categoryIndex !== -1) {
            const toggle = await toggleCategory(categories[categoryIndex]?.id);
            if (toggle.error) {
              toast.error(toggle.error);
            } else {
              updatedCategories[categoryIndex].hidden =
                !updatedCategories[categoryIndex].hidden;
            }
          }
        }
        setCategories(updatedCategories);
      } else {
        toast.error("Please select category first");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while hiding category");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmRemoveCategory = (category) => {
    setSelectedCategory(category);
    showRemoveDialog();
  };

  const handleConfirmRemoveSubCategory = (category, parentCategory) => {
    setSelectedParentCategory(parentCategory);
    setSelectedCategory(category);
    showRemoveDialog();
  };

  const columns = [
    { Header: "Select", accessor: "select" },
    { Header: "Name", accessor: "name" },
    {Header: "Position",accessor:"position"},

    { Header: "Hidden", accessor: "hidden" },
    { Header: "Action", accessor: "icon" },
  ];

  const prodColumns = [
    { Header: "Name", accessor: "name" },
    { Header: "Action", accessor: "icon" },
  ];

  const getCatData = async () => {
    // console.log("await", await fetchCategoriesAndSubcategories());
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setRole(user.role);
    getCatData();
    fetchCategoriesAndSubcategories().then((data) => setCategories(data));
    fetchProductGroupsAndProducts().then((data) => setProductGroup(data));
  }, []);

  return (
    <>
      {loading && <LoadingSpinner />}
      <h1 className="text-2xl font-bold text-gray-800 mb-4 border-b-2 border-gray-300 pb-2">
        Categories:
      </h1>
      <div className="flex justify-end">
        <button
          onClick={openCategoryModal}
          className="mt-4 mb-4 bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded">
          Add Category
        </button>
      </div>
      <CreateCategoryModal
        isOpen={categoryModalIsOpen}
        onRequestClose={closeCategoryModal}
        onCreateRequest={handleAddCategory}
        onEditRequest={handleEditCategory}
        categoryData={selectedCategory}
        isSubCategory={Object.keys(selectedParentCategory).length > 0}
        productGroupData={productGroup}
      />

      {role == "ADMIN" ? (
        <CategoriesTableDragable
          data={categories}
          columns={columns}
          onEditCategory={handleEditCategoryModal}
          onRemoveCategory={handleConfirmRemoveCategory}
          onEditSubCategory={handleEditSubCategoryModal}
          onRemoveSubCategory={handleConfirmRemoveSubCategory}
          onHideCategory={handleHideCategory}
          catModal={openSubCategoryModal}
          isSub={true}
          setpgName={setpgName}
        />
      ) : (
        <CategoriesTable
          data={categories}
          columns={columns}
          onEditCategory={handleEditCategoryModal}
          onRemoveCategory={handleConfirmRemoveCategory}
          onEditSubCategory={handleEditSubCategoryModal}
          onRemoveSubCategory={handleConfirmRemoveSubCategory}
          onHideCategory={handleHideCategory}
          catModal={openSubCategoryModal}
          isSub={true}
          setpgName={setpgName}
        />
      )}

      <ConfirmationDialog
        isOpen={removeDialogIsOpen}
        onRequestClose={hideRemoveDialog}
        title="Are you sure?"
        message="Do you want to remove the selected category? This will also remove all subcategories"
        onConfirm={handleRemoveCategory}
      />

      {/* /.find((x) => x.id == selectedCheckbox) */}
      {pgName ? (
        <>
          <h1 className="mt-3 text-2xl font-bold text-gray-800 mb-4 border-b-2 border-gray-300 pb-2">
            Product Groups
          </h1>
          <div className="mt-3">
            {productGroup && (
              <ProductGroupTable
                onEditProductGroup={handleEditProductGroupModal}
                data={productGroup.filter((x) => x.name == pgName)}
                columns={prodColumns}
              />
            )}
            {!productGroup && <LoadingSpinner />}
            <EditProductGroupModal
              isOpen={productGroupModalIsOpen}
              onRequestClose={closeProductGroupModal}
              onEditRequest={handleEditProductGroup}
              productGroupName={selectedProductGroup}
            />
          </div>
        </>
      ) : null}
      <ToastContainer />
    </>
  );
};

export default EditBuilds;
