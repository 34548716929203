import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import ImageUploader from "./shared/ImageUploader";

Modal.setAppElement("#root");

const CreateProductModal = ({
  isOpen,
  onClose,
  onSave,
  onEdit,
  selectedProduct,
}) => {
  const [productDetails, setProductDetails] = useState({
    name: "",
    listPrice: "",
    unitCost: "",
    dedicatedHeadCount: "",
    squareFootage: "",
    link: "",
    selectedFile: null,
    desc:""
  });

  const [error, setError] = useState("");

  useEffect(() => {
    if (selectedProduct && selectedProduct.name) {
      const {
        name,
        listPrice,
        unitCost,
        dedicatedHeadCount,
        squareFootage,
        link,
        selectedFile,
        desc
      } = selectedProduct;
      let image = "" 
      try {
        if (typeof selectedFile == "object") {
          image = selectedFile;

        } else {
          image = JSON.parse(selectedFile);
        }
      } catch (e) {}
     
      setProductDetails({
        name,
        listPrice,
        unitCost,
        dedicatedHeadCount,
        squareFootage,
        link,
        selectedFile: image,
        desc
      });
    } else {
      setProductDetails({
        name: "",
        listPrice: "$",
        unitCost: "$",
        dedicatedHeadCount: "",
        squareFootage: "",
        link: "",
        selectedFile: null,
        desc:""
      });
    }
  }, [selectedProduct]);

  const handlePriceChange = (e) => {
    const { name, value } = e.target;

    const formattedValue = value.startsWith("$") ? value : `$${value}`;
    // const decimalFormattedValue = /^\$[\d.]*$/.test(formattedValue) ? formattedValue : '$';

    const sanitizedValue = formattedValue.replace(/[^$\d.]/g, "");

    if (sanitizedValue === "" || sanitizedValue.startsWith("$")) {
      setProductDetails((prevState) => ({
        ...prevState,
        [name]: sanitizedValue,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (value) => {
    console.log("insof", typeof value);
    if (typeof value == "string") {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      const jsonObject = {
        imageUrl: value.name,
        imageData: base64String,
      };
      setProductDetails((prevState) => ({
        ...prevState,
        selectedFile: JSON.stringify(jsonObject),
      }));
      console.log(JSON.stringify(jsonObject));
      // setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(value);
    console.log("base", value);
  };

  const handleSave = () => {
    if (selectedProduct && selectedProduct.name) {
      onEdit(selectedProduct.name, productDetails);
    } else {
      onSave(productDetails);
    }
    setProductDetails({
      name: "",
      listPrice: "",
      unitCost: "",
      dedicatedHeadCount: "",
      squareFootage: "",
      link: "",
      selectedFile: null,
      desc:""
    });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={
        selectedProduct && selectedProduct.name ? "Edit Product" : "Add Product"
      }
      className="max-w-2xl w-3/4 p-6 mx-auto my-16 bg-white rounded shadow-lg"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
      {/* <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"> */}
      <div className="flex flex-col items-center">
        <h2 className="text-xl mb-4">
          {selectedProduct && selectedProduct.name
            ? "Edit Product"
            : "Add Product"}
        </h2>
        <form onSubmit={handleSave} className="w-full">
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block mb-2">Name</label>
              <input
                required
                name="name"
                value={productDetails.name}
                onChange={handleChange}
                className="w-full border p-2"
              />
            </div>
            <div>
              <label className="block mb-2">List Price $</label>
              <input
                required
                name="listPrice"
                placeholder="$"
                value={productDetails.listPrice}
                onChange={handlePriceChange}
                className="w-full border p-2"
              />
            </div>
            <div>
              <label className="block mb-2">Unit Cost $</label>
              <input
                required
                name="unitCost"
                placeholder="$"
                value={productDetails.unitCost}
                onChange={handlePriceChange}
                className="w-full border p-2"
              />
            </div>
            <div>
              <label className="block mb-2">Dedicated Head Count</label>
              <input
                required
                name="dedicatedHeadCount"
                value={productDetails.dedicatedHeadCount}
                onChange={handleChange}
                className="w-full border p-2"
              />
            </div>
            <div>
              <label className="block mb-2">Square Feet</label>
              <input
                required
                name="squareFootage"
                value={productDetails.squareFootage}
                onChange={handleChange}
                className="w-full border p-2"
              />
            </div>
            <div>
              <label className="block mb-2">Link</label>
              <input
                required
                name="link"
                value={productDetails.link}
                onChange={handleChange}
                className="w-full border p-2"
              />
            </div>
            
          </div>
          <div>
              <label className="block mb-2">Description</label>
              <textarea
                required
                name="desc"
                value={productDetails.desc}
                onChange={handleChange}
                className="w-full border p-2"
              />
            </div>
          <ImageUploader
            onImageChange={(image) => {
              handleImageChange(image);
              setError("");
            }}
            imageUrl={productDetails.selectedFile}
          />
          <div className="flex items-center justify-end">
            <button
              type="submit"
              className="bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              {selectedProduct && selectedProduct.name
                ? "Edit Product"
                : "Add Product"}
            </button>

            <button
              type="button"
              onClick={onClose}
              className="bg-slate-400 hover:bg-slate-500 ml-1 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Cancel
            </button>
          </div>
        </form>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default CreateProductModal;
