import React, { useEffect, useState } from "react";
import { FiInfo } from "react-icons/fi";

import { useModal } from "../hooks/useModal";

export default function CategoryBox({
  title,
  subCategories,
  allSelected,
  addSelectionHandler,
  removeSelectionHanlder,
  selected,
}) {
  return (
    <div className="chk-list-box col-lg-33 col-sm-12">
      <h2 className="f-16 f-bold mb-3">{title}</h2>
      {subCategories &&
        subCategories.map((subcategory) => (
          <SubcategoryBox
            id={subcategory.id}
            name={subcategory.name}
            text={subcategory.text}
            imageUrl={subcategory.imageUrl}
            allSelected={allSelected}
            addSelectionHandler={addSelectionHandler}
            removeSelectionHanlder={removeSelectionHanlder}
            selected={selected.includes(subcategory.id)}
          />
        ))}
    </div>
  );
}

const SubcategoryBox = ({
  id,
  name,
  imageUrl,
  text,
  removeAllSelected,
  allSelected,
  addSelectionHandler,
  removeSelectionHanlder,
  selected,
}) => {
  const { openModal } = useModal();

  useEffect(() => {
    if (allSelected) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [allSelected]);

  useEffect(() => {
    if (selected) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, []);
  const [isSelected, setIsSelected] = useState(false);

  const handleSelected = (e) => {
    if (!e.target.checked) {
      //removeAllSelected();
      setIsSelected(false);
      removeSelectionHanlder(id);
    } else {
      setIsSelected((prev) => !prev);
      addSelectionHandler(id);
    }
  };

  const handleIconClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    openModal({ title: name, image: imageUrl, text }, "subcategory");
  };

  return (
    <div className="d-flex align-items-center mb-2 f-16">
      {/* <input type="checkbox" class="mr-2"> U-Shaped */}
      <label className="label-container">
        {name}
        <FiInfo
          className="ml-2 inline text-gray-500 cursor-pointer"
          onClick={handleIconClick}
        />
        <input checked={isSelected} onChange={handleSelected} type="checkbox" />
        <span className="checkmark" />
      </label>
    </div>
  );
};
