import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchProjectById } from "../lib/api/ProjectHandler";
import { fetchBuildByName } from "../lib/api/BuildHandler";
import { fetchCategoriesAndSubcategoriesByPGnames } from "../lib/api/CategoriesHandler";
import { toast } from "react-toastify";

const EditBuild = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const isLoggedIn = () => {
    return (
      localStorage.getItem("isAuthenticated") &&
      localStorage.getItem("isAuthenticated") == "true"
    );
  };

  useEffect(() => {
    const handleEditBuild = async () => {
        sessionStorage.removeItem("password");
        localStorage.removeItem("build");
        localStorage.removeItem("products");
        sessionStorage.removeItem("selectedItems");

        try {
            const projectId = searchParams.get("projectId");
            const buildName = searchParams.get("buildName");

            if (!projectId) {
                setMessage("Please provide a correct build link, project id was not found in the link.");
                return;
            }

            if (!isLoggedIn()) {
                setMessage(
                    "You must be loggedin to edit a build. If you don't have an account please create on. Redirecting to login page..."
                );
                setTimeout(() => navigate("/login"), 5000); // Redirect to login after 3 seconds
            } else {
                setMessage("Redirecting you to the build page...");
    
                setTimeout(() => navigate("/home/"), 3000); // Redirect to login after 3 seconds
            }

            const project = await fetchProjectById(projectId);
            const res = await fetchBuildByName(buildName);

            if (res.error) {
                console.log(res.error);
                toast.error("Build not found!");
                setMessage("Build not found");
                return;
            }
            
            // console.log("kkkkkkk",res[0][0].selectedCats)
            const finalData = res[0];
        
            const pgs = [];
            for (const key of Object.keys(finalData)) {
                if (typeof finalData[key] == "object") {
                    finalData[key].identified = finalData[key].name + finalData[key].tag;
            
                    pgs.push(finalData[key].name);
                }
            }
            const ttv = await fetchCategoriesAndSubcategoriesByPGnames(pgs);
            console.log("ttv", JSON.stringify(ttv));
            const subcategoryIds = ttv.flatMap((item) =>
                item.subcategories.map((sub) => sub.id)
            );
            sessionStorage.setItem("selectedItems", JSON.stringify(subcategoryIds));
        
            console.log("project kk", JSON.stringify(project));
            localStorage.setItem("project", project.link);
            localStorage.setItem("build", buildName);
            localStorage.removeItem("products");
            sessionStorage.setItem(
                "selectedItems",
                JSON.stringify(res[0][0]?.selectedCats)
            );
            window.open(`/home/build`, "_blank");
        } catch(err) {
            console.log(err);   
            toast.error("Project does not exist!");
            setMessage("Project not found");
        }
    };

    handleEditBuild();
  }, [searchParams, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h1 className="text-3xl font-extrabold text-gray-900 text-center">
            View Build
          </h1>
          <p className="mt-2 text-sm text-gray-600 text-center">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default EditBuild;
