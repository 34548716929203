import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import { FcBullish } from 'react-icons/fc'
import { HiOutlineLogout } from 'react-icons/hi'
import { DASHBOARD_SIDEBAR_LINKS, DASHBOARD_SIDEBAR_BOTTOM_LINKS } from '../../lib/constants'

const linkClass =
	'flex items-center gap-2 font-light px-3 py-2 hover:bg-red-950 hover:no-underline active:bg-red-950 rounded-sm text-base'

export default function Sidebar() {

	const handleLogoutClick = () => {
		window.location.href = '/logout'
	}

	const [role, setRole] = useState('');

	useEffect(() => {
		if(localStorage.getItem('user') ){
			const user = JSON.parse(localStorage.getItem('user'));
			setRole(user.role);
		}
	}, [])
	

	return (
		<div className="bg-stone-950 w-60 p-3 flex flex-col">
			<div className="flex items-center gap-2 px-1 py-3">
				{/* <FcBullish fontSize={24} />
				<span className="text-neutral-200 text-lg">OpenShop</span> */}
                {/* <img src={process.env.PUBLIC_URL + "/logo.png" } alt="bos.com" /> */}
			</div>
			<div className="py-8 flex flex-1 flex-col gap-0.5">
				{DASHBOARD_SIDEBAR_LINKS.map((link) => (
					( link.role.includes(role) && <SidebarLink key={link.key} link={link} /> )
				))}
			</div>
			<div className="flex flex-col gap-0.5 pt-2 border-t border-neutral-700">
				{DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((link) => (
					<SidebarLink key={link.key} link={link} />
				))}
				<div onClick={handleLogoutClick} className={classNames(linkClass, 'cursor-pointer text-red-500')}>
					<span className="text-xl">
						<HiOutlineLogout />
					</span>
					Logout
				</div>
			</div>
		</div>
	)
}

function SidebarLink({ link }) {
	const { pathname } = useLocation()

	return (
		<Link
			to={link.path}
			className={classNames(pathname === link.path ? 'bg-red-900 text-white' : 'text-neutral-400', linkClass)}
		>
			<span className="text-xl">{link.icon}</span>
			{link.label}
		</Link>
	)
}