import React, { useEffect, useState, useCallback, useRef } from "react";
import ProductBox from "./shared/ProductBox";
import classNames from "classnames";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  fetchCategoriesAndSubcategories,
  fetchCategoriesAndSubcategoriesByPGnames,
  fetchCategoriesAndSubcategoriesBySubIds,
} from "../lib/api/CategoriesHandler";
import { fetchProductGroupsAndProducts } from "../lib/api/ProductGroupHanlder";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { fetchProjectById, fetchProjectsWithLink } from "../lib/api/ProjectHandler";
import { useModal } from "./hooks/useModal";
import LoadingSpinner from "../pages/LoadingSpiner";
import { useSearchParams } from "react-router-dom";
import { fetchBuildById, fetchBuildByName, findBuildByName } from "../lib/api/BuildHandler";
import { convertToGroupedObject } from "../lib/helpers";
import { toast } from "react-toastify";

export default function Build() {
  const [searchParams] = useSearchParams();
  const dataParam = sessionStorage.getItem("selectedItems");
  const subcategoryIds =
    dataParam && dataParam != "undefined" ? JSON.parse(dataParam) : [];
  const [noProject, setNoProject] = useState(false);

  const [fromReport, setFromReport] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const [fetchBuildCompleted, setFetchBuildCompleted] = useState(false);

  const { openModal, passwordPopupState } = useModal();

  const { showPopup: showPasswordPopup, setShowPopup: setShowPasswordPopup } =
    passwordPopupState;

  const [categories, setCategories] = useState(null);
  const [productGroups, setProductGroups] = useState(null);

  const [showFooter, setShowFooter] = useState(true);
  const [margin, setMargin] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [nextButtonText, setNextButtonText] = useState("Next");

  const [buildEdited, setBuildEdited] = useState(false);


  useEffect(() => {
    const savedProducts = JSON.parse(localStorage.getItem('products'));
    if (savedProducts) {
      setProducts(savedProducts);
    }
  }, []);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    let mergedCategoriesData = await fetchCategoriesAndSubcategoriesBySubIds(
      subcategoryIds
    );
    if (mergedCategoriesData && mergedCategoriesData.length > 0) {
      mergedCategoriesData = mergedCategoriesData.sort(
        (a, b) => a.position - b.position
      );

      mergedCategoriesData.forEach((item) => {
        if (item.subcategories && Array.isArray(item.subcategories)) {
          item.subcategories.sort((a, b) => a.position - b.position);
        }
      });
    }

    console.log("mergedCategoriesData", mergedCategoriesData);
    const productGroupData = await fetchProductGroupsAndProducts();
    if (mergedCategoriesData.length > 0) {
      const sortedCategories = mergedCategoriesData.sort(
        (a, b) => a.position - b.position
      );
      setCategories(sortedCategories);
    }
    console.log("productGroupData", productGroupData);

    const link = localStorage.getItem("project");
    const project = await fetchProjectsWithLink(link);
    if (project.message == "Project does not exist") {
      setNoProject(true);
    } else {
      setMargin(project.margin);
    }
    console.log("settingmargin", project.margin);

    const isPasswordEntered = sessionStorage.getItem("password");

    if (project.password && project.password == "true" && !isPasswordEntered) {
      console.log("password state ", passwordPopupState);
      setShowPasswordPopup(true);
    } else {
      setShowPasswordPopup(false);
    }

    setProductGroups(productGroupData);
    console.log("pdata", productGroupData);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setTimeout(() => fetchData(), 500);
  }, [fetchBuildCompleted]);

  useEffect(() => {
    fetchBuild();
  }, []);

  useEffect(() => {
    const handleCustomEvent = (event) => {
      console.log("Custom event received:", event.detail);
      handleSaveBuildEvent(event.detail.callbackFc);
    };

    window.addEventListener("myCustomEvent", handleCustomEvent, true);

    return () => {
      window.removeEventListener("myCustomEvent", handleCustomEvent, true);
    };
  }, []);

  const [activeCategory, setActiveCategory] = useState(null);

  const [totalListPrice, setTotalListPrice] = useState(0);

  const [products, setProducts] = useState([]);

  const [totalSellPrice, setTotalSellPrice] = useState(0);
  const [totalSeats, setTotalSeats] = useState(0);
  const [totalSquareFootage, setTotalSquareFootage] = useState(0);
  const [pricePerSquateFootage, setPricePerSquateFootage] = useState(0);

  const [buildName, setBuildName] = useState("");

  const [confirmed, setConfirmed] = useState(false);
  const [reportConfirmation, setReportConfirmation] = useState(false);

  const [buildNotFound, setBuildNotFound] = useState("N/A");

  const { build_id } = useParams();

  const location = useLocation();



  const openConfirmationModal = () => {
    setModalVisible(true);
  };

  const closeConfirmationModal = () => {
    setModalVisible(false);
  };

  const confirmNewBuild = () => {
    setConfirmed(true);
    closeConfirmationModal();
  };
  // useEffect(() => {
  //   // setProducts(JSON.parse(localStorage.getItem("products")))
  // })
  useEffect(() => {
    if (confirmed && reportConfirmation) {
      handleReport();
      setReportConfirmation(false);
    } else if (confirmed) {
      hanldeNextClick();
    }
  }, [confirmed]);

  useEffect(() => {
    console.log('items in local', localStorage.getItem("products"));
    const alreadyAdded = localStorage.getItem("products")
      ? JSON.parse(localStorage.getItem("products"))
      : null;
    if (!alreadyAdded || !products || products.length == 0) {
      console.log('items in returning', alreadyAdded)
      console.log('items in products', products);
      console.log('items in products.le', products.length);
      return;
    }

    const finalData = localStorage.getItem("products")
      ? JSON.parse(localStorage.getItem("products"))
      : null;
    const prods = [];

    for (const key of Object.keys(finalData)) {
      if (typeof finalData[key] == "object") {
        finalData[key].identified = finalData[key].name + finalData[key].tag;
        const pd = products.find(
          (c) => c.identified == finalData[key].identified
        );
        if (pd) {
          pd.quantity = finalData[key].quantity;
          prods.push(pd);
          incrementItems(
            pd.name,
            pd.tag,
            pd.price,
            finalData[key].quantity,
            pd.sellPrice,
            pd.squarFootage,
            pd.category,
            pd.productName,
            pd.productImage,
            finalData[key].dedicatedHeadCount
          );
          // if(products && products.length > 0){
          //   console.log('before', products);
          //   setProducts(prev => {

          //       prev.map((item) =>
          //         item.identified === finalData[key].identified ? { ...item, quantity: finalData[key].quantity } : item
          //     )

          //   });
          // }
        }
      }
    }
    console.log("prods", prods);
    // setProducts(prods);
  }, [productGroups]);

  const fetchBuild = async () => {
    const buildToEdit = localStorage.getItem("build");

    if (buildToEdit) {
      setFromReport(true);

      const lsproducts = JSON.parse(localStorage.getItem("products"));

      console.log("lsproducts: ", lsproducts);

      const res = await fetchBuildByName(buildToEdit);

      if (res) {
        setBuildName(buildToEdit);
        let finalData = [];
        if (lsproducts && lsproducts.length > 0) {
          finalData = lsproducts;
        } else {
          finalData = res[0];
        }

        let prods = [];
        const pgs = [];
        for (const key of Object.keys(finalData)) {
          if (typeof finalData[key] == "object") {
            finalData[key].identified =
              finalData[key].name + finalData[key].tag;
            if (finalData[key].quantity > 0) {
              prods.push(finalData[key]);
            }
            pgs.push(finalData[key].name);
          }
        }

        console.log("lsproducts 2: ", prods);
        const selected = sessionStorage.getItem("selectedItems");
        const mergedCategoriesData =
          await fetchCategoriesAndSubcategoriesBySubIds(selected);

        const validCategories = mergedCategoriesData.map((item) => item.name);

        // Filter prods to keep only items with a category present in validCategories
        const filteredProds = prods.filter((prod) =>
          validCategories.includes(prod.category)
        );
        prods = filteredProds;

        // const uniqueArray = prods.filter((item, index, self) =>
        //   index === self.findIndex((t) => (
        //     t.identified === item.identified // Use the property you want to check for uniqueness
        //   ))
        // );

        // console.log("filteredProds",uniqueArray)
        // console.log("filteredProds existing",products)
        // uniqueArray.forEach(p => {
        //   if(!products.some(pr => p.identified == pr.identified)){
        //     setProducts(prev => [...prev, p] );
        //   }
        // })
        setProducts(prods);

        localStorage.setItem("products", JSON.stringify(prods));
        console.log("lsproducts 3: ", prods);
        // populateQuantity();
        console.log("prods", prods);
        if (prods) {
          console.log("prr", prods);
          if (!categories || categories.length == 0) {
            console.log("pgs", pgs);
            const ttv = await fetchCategoriesAndSubcategoriesByPGnames(pgs);
            console.log("ppppkkkkk", ttv);
            setCategories(ttv);
          }
          console.log("pact", categories);
          prods.name = buildToEdit;

          console.log("name", prods);
          //localStorage.setItem("build",buildToEdit)
          //localStorage.setItem("products", JSON.stringify(prods));
          setFetchBuildCompleted(true);
        }
      }
    }
  };

  function processCurrencyString(currencyString, multiplier) {
    console.log("quan put", multiplier);
    // Step 1: Remove the dollar sign and extra periods
    let numericString = currencyString.replace(/[^\d.]/g, "").replace(".", ""); // Remove everything except digits and periods
    let parts = numericString.split("."); // Split by period to handle multiple dots
    let cleanedString = parts[0] + "." + parts.slice(1).join(""); // Combine the parts correctly

    // Step 2: Convert to float and multiply
    let number = parseFloat(cleanedString);
    let multipliedValue = number * multiplier;

    // Step 3: Format back to string with dollar sign
    let resultString = multipliedValue.toFixed(2); // To ensure two decimal places

    return resultString;
  }

  // useEffect(() => {
  //   //yahan
  //   if (localStorage.getItem("products")) {
  //     setBuildName(JSON.parse(localStorage.getItem("products").name) || "");
  //   }
  // }, []);

  useEffect(() => {
    if (activeCategory) {
      const index = categories.indexOf(activeCategory);
      if (index !== -1 && index == categories.length - 1) {
        setNextButtonText("Create Report");
      } else {
        setNextButtonText("Next");
      }
    }
  }, [activeCategory]);

  useEffect(() => {
    const atleastOneProdSelected = products.some(
      (product) => product.quantity > 0
    );

    const build = { ...products };
    //yahan
    console.log("thisname", products);
    build.name = localStorage.getItem("build") || products?.name || "";

    // localStorage.setItem(
    //   "products",
    //   JSON.stringify(
    //     products
    //       .filter((product) => product.quantity > 0)
    //       .map((product) => product)
    //   )
    // );

    console.log("products here");

    setTotalListPrice(
      products.reduce((acc, item) => addFloats(acc, item.price), 0)
    );
    setTotalSellPrice(
      products.reduce((acc, item) => addFloats(acc, item.sellPrice), 0)
    );
    setTotalSquareFootage(
      products.reduce((acc, item) => addFloats(acc, item.squarFootage), 0)
    );

    setTotalSeats(products.reduce((acc, item) => acc + (item.dedicatedHeadCount * item.quantity), 0));
  }, [products]);

  useEffect(() => {
    setPricePerSquateFootage(totalSellPrice / (totalSquareFootage *1.18) || 0);
  }, [totalSellPrice, totalSquareFootage]);

  const incrementItems = (
    name,
    tag,
    newPrice,
    newQuantity,
    newSalePrice,
    newSquareFootage,
    category,
    productName,
    productImage,
    dedicatedHeadCount
  ) => {
    console.log(
      "prodss ",
      name,
      tag,
      newPrice,
      newQuantity,
      newSalePrice,
      newSquareFootage,
      category,
      productName,
      productImage
    );
    // const updateCat = (name, newPrice) => {
    console.log("inside increment");
    // window.addEventListener("beforeunload", handleBeforeUnload, true);

    setProducts((prevCat) => {
      const index = prevCat.findIndex((item) => item.identified === name + tag);
      if (index !== -1) {
        console.log("wow", newQuantity);
        setBuildEdited(true);
        // Update existing item
        return prevCat.map((item) =>
          item.identified === name + tag
            ? {
                ...item,
                tag,
                quantity: newQuantity,
                price: newPrice,
                sellPrice: newSalePrice,
                squarFootage: newSquareFootage,
                productName,
                productImage,
                dedicatedHeadCount,
              }
            : item
        );
      } else {
        // Add new item
        console.log("wow", newQuantity);
        return [
          ...prevCat,
          {
            identified: name + tag,
            category,
            name,
            tag,
            quantity: newQuantity,
            price: newPrice,
            sellPrice: newSalePrice,
            squarFootage: newSquareFootage,
            productName,
            productImage,
            dedicatedHeadCount
          },
        ];
      }
    });
    if (products.length > 0) {
      console.log("lsproducts 4: ", products);
      localStorage.setItem(
        "products",
        JSON.stringify(
          products
            .filter((product) => product.quantity > 0)
            .map((product) => product)
        )
      );
    }
    // };
  };

  //   setTotalSellPrice(prev => prev + sellPrice);
  //   setTotalSquareFootage(prev => prev + squareFootage);
  // }

  function addFloats(num1, num2, precision = 10) {
    // Convert to fixed precision to avoid floating point issues
    let result = (parseFloat(num1) + parseFloat(num2)).toFixed(2);
    // Convert back to float to remove trailing zeros
    return parseFloat(result);
  }

  const handleSaveBuild = () => {
    openModal({ successcb }, "new-build");
  };

  const handleSaveBuildEvent = (cb) => {
    openModal({ successcb: cb }, "new-build");
  };

  const successcb = () => {
    // window.removeEventListener("beforeunload", handleBeforeUnload, true);
    setBuildEdited(false);
  };

  const gotoReport = () => {
    window.location.href = "/home/report";
  };

  const handleReport = () => {
    const anyProduct = products.some((p) => p.quantity > 0);
    if (!anyProduct) {
      toast.error("Please select at least one product with quantity");
      return;
    }
    const filteredProducts = products.filter(
      (product) => product.quantity == 0
    );

    const productNamesWithQuantityGreaterThanZero = new Set(
      products
        .filter((product) => product.quantity > 0)
        .map((product) => product.name)
    );

    const filteredFilterArray = filteredProducts.filter(
      (product) =>
        product.quantity === 0 &&
        !productNamesWithQuantityGreaterThanZero.has(product.name)
    );
    console.log("filteredFilterArray", filteredFilterArray);
    if (!confirmed && filteredFilterArray && filteredFilterArray.length > 0) {
      console.log("woww", filteredProducts);
      setReportConfirmation(true);
      openConfirmationModal();
      return;
    }
    if (
      !buildEdited &&
      localStorage.getItem("products") &&
      localStorage.getItem("build")
    ) {
      window.location.href = "/home/report";
    } else {
      openModal({ successcb: gotoReport }, "new-build");
    }
  };

  const hanldeNextClick = () => {
    const index = categories.indexOf(activeCategory);
    if (index !== -1 && index < categories.length - 1) {
      setActiveCategory(categories[index + 1]);
      setTimeout(
        () =>
          window.scrollTo({
            top: 100,
            behavior: "smooth", // Optional: Adds smooth scrolling
          }),
        100
      );
    } else {
      const anyProduct = products.some((p) => p.quantity > 0);
      if (!anyProduct) {
        toast.error("Please select at least one product with quantity");
        return;
      }

      const filteredProducts = products.filter(
        (product) => product.quantity == 0
      );

      const productNamesWithQuantityGreaterThanZero = new Set(
        products
          .filter((product) => product.quantity > 0)
          .map((product) => product.name)
      );

      const filteredFilterArray = filteredProducts.filter(
        (product) =>
          product.quantity === 0 &&
          !productNamesWithQuantityGreaterThanZero.has(product.name)
      );
      console.log("filteredFilterArray", filteredFilterArray);
      if (!confirmed && filteredFilterArray && filteredFilterArray.length > 0) {
        console.log("woww", filteredProducts);
        openConfirmationModal();
        return;
      }
      if (
        !buildEdited &&
        localStorage.getItem("products") &&
        localStorage.getItem("build")
      ) {
        window.location.href = "/home/report";
      } else {
        openModal({ successcb: gotoReport }, "new-build");
      }
    }
  };

  const hanldeBackClick = () => {
    const index = categories.indexOf(activeCategory);
    if (index !== -1 && index > 0) {
      setActiveCategory(categories[index - 1]);
      setTimeout(
        () =>
          window.scrollTo({
            top: 100,
            behavior: "smooth", // Optional: Adds smooth scrolling
          }),
        100
      );
    } else {
      const buildToEdit = searchParams.get("project");
      window.location.href = `/home`;
    }
  };

  const toggleFooterVisibility = () => {
    setShowFooter((footer) => !footer);
  };

  useEffect(() => {
    if (categories && categories.length > 0) setActiveCategory(categories[0]);
  }, [categories]);

  if (buildNotFound !== "N/A" && buildNotFound) {
    return (
      <>
        {noProject && <ProjectPopUp setNoProject={setNoProject} />}
        {isLoading && <LoadingSpinner />}
        <div className="container text-center my-5 vh-100">
          <h1 className="my-4 error-heading">Build not found</h1>
          <h5 className="mb-4 error-text" style={{ color: "black" }}>
            This build does not exist
          </h5>
          <Link
            to="/builds"
            className="bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            View Other Builds
          </Link>
        </div>
      </>
    );
  }

  return (
    <>
      {noProject && <ProjectPopUp setNoProject={setNoProject} />}
      {isLoading && <LoadingSpinner />}
      <div className="row m-0">
        <div className="col-lg-12">
          <section id="content">
            <div className="section-wrapper">
              <div className="section-wrapper">
                <div className="tab-wrap">
                  <a
                    href="/home"
                    className="nav-item nav-link theme-primary-color"
                    id="nav-home-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/specify.png"
                      }
                    />
                    Specify
                  </a>
                  <a
                    className="nav-item nav-link active-tab"
                    id="nav-profile-tab"
                    data-toggle="tab"
                    href="#nav-profile"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/build-icon.png"
                      }
                    />
                    Build
                  </a>
                  <a
                    className="nav-item nav-link"
                    href="#"
                    onClick={handleReport}>
                    {" "}
                    <img src={"/assets/images/download-icon.png"} /> Report
                  </a>
                </div>
                <div className="chkbox-wrap container mt-4 pt-5">
                  <div className="container mb-4 build-tab-nav-wrap">
                    <ul className="row justify-content-start">
                      {activeCategory &&
                        categories.map((category, index) => (
                          <li
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() => setActiveCategory(category)}
                            className={
                              activeCategory.name == category.name
                                ? "active"
                                : ""
                            }>
                            {category.name}
                          </li>
                        ))}

                      {/* // <li className="active">Workstations</li>
                // <li>Private Offices</li>
                // <li>Seating</li>
                // <li>Meeting Rooms</li>
                // <li>Tables</li>
                // <li>Lounge</li>
                // <li>Gathering Spaces</li>
                // <li>Additional</li> */}
                    </ul>
                  </div>
                  {activeCategory &&
                    productGroups &&
                    activeCategory.subcategories.map((subcategory, index) => (
                      <>
                        <div>
                          <h2
                            className={classNames(
                              index > 0 ? "mt-4" : "",
                              "f-20 theme-primary-color f-bold"
                            )}>
                            {
                              productGroups.find(
                                (pGroup) =>
                                  pGroup.name == subcategory.productGroup
                              )?.name
                            }
                          </h2>
                        </div>
                        <div className="threeBox-list-wrap">
                          <ProductBox
                            key={`${activeCategory.name}-${index}`}
                            productGroup={productGroups.find(
                              (pGroup) =>
                                pGroup.name == subcategory.productGroup
                            )}
                            onIncrementItems={incrementItems}
                            category={activeCategory.name}
                            products={products}
                            margin={margin}
                          />
                        </div>
                      </>
                    ))}
                  <div className="btn-wrap text-center p-5 d-flex justify-content-between">
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#"
                      className="btn-round"
                      onClick={hanldeBackClick}>
                      Back
                    </button>
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#save-build-modal"
                      className="btn-round d-flex align-items-center"
                      onClick={handleSaveBuild}>
                      save{" "}
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/save-icon.png"
                        }
                        className="pl-2"
                      />
                    </button>
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target=""
                      className="btn-round"
                      onClick={hanldeNextClick}>
                      {nextButtonText}
                    </button>
                  </div>
                </div>
              </div>{" "}
              {/* Section ends here */}
            </div>
          </section>
        </div>

        <button
          className="fixed bottom-4 right-4 py-1 px-2 bg-red-900 text-white rounded"
          onClick={toggleFooterVisibility}
          style={{ zIndex: 1000 }}>
          {showFooter ? <FaAngleDown size={10} /> : <FaAngleUp size={10} />}
        </button>
        {showFooter && (
          <div
            className="container-fluid footer-tag-wrap"
            style={{ paddingTop: "28px" }}>
            <div className="container">
              <div className="footer-tag-item">
                <div>
                  <img
                    src="/assets/images/people-icon-f.png"
                    alt="People Icon"
                  />
                </div>
                <div>
                  <h3>Dedicated Seats</h3>
                  <h2 className="theme-primary-color">
                    {totalSeats.toLocaleString("en-US")}
                  </h2>
                </div>
              </div>
              <div className="footer-tag-item">
                <div>
                  <img
                    src="/assets/images/doller-icon-f.png"
                    alt="Dollar Icon"
                  />
                </div>
                <div>
                  <h3>Total List Price</h3>
                  <h2 className="theme-primary-color text-cross">
                    $
                    {totalListPrice.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h2>
                </div>
              </div>
              <div className="footer-tag-item">
                <div>
                  <img
                    src="/assets/images/per-icon-f.png"
                    alt="Percentage Icon"
                  />
                </div>
                <div>
                  <h3>Total Sell Price</h3>
                  <h2 className="theme-primary-color">
                    $
                    {!!totalSellPrice && !isNaN(totalSellPrice)
                      ? totalSellPrice.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "0.00"}
                  </h2>
                </div>
              </div>
              <div className="footer-tag-item">
                <div>
                  <img src="/assets/images/scale-icon-f.png" alt="Scale Icon" />
                </div>
                <div>
                  <h3>Total Square Feet</h3>
                  <h2 className="theme-primary-color">
                    {totalSquareFootage.toLocaleString("en-US")}
                  </h2>
                </div>
              </div>
              <div class="footer-tag-item">
                <div>
                  <img src="/assets/images/doller-outlne-icon-f.png" alt="" />
                </div>
                <div>
                  <h3>Price Per Square Foot</h3>
                  <h2 class="theme-primary-color">
                    $
                    {pricePerSquateFootage.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ConfirmModal
        show={modalVisible}
        onClose={closeConfirmationModal}
        onConfirm={confirmNewBuild}
      />
    </>
  );
}

const ConfirmModal = ({ show, onClose, onConfirm }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
      }}>
      <div
        style={{
          backgroundColor: "white",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          textAlign: "center",
          width: "300px",
          padding: "20px",
        }}>
        <p style={{ fontSize: "16px", marginBottom: "20px" }}>
          Your build contains product categories without any quantities
          selected. Would you still like to generate a report?
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={onConfirm}
            style={{
              backgroundColor: "#c8102e",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              margin: "10px",
              width: "100px",
            }}>
            OK
          </button>
          <button
            onClick={onClose}
            style={{
              backgroundColor: "#242531",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              margin: "10px",
              width: "100px",
            }}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const popupOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const popupContentStyle = {
  backgroundColor: "#fff",
  padding: "18px",
  borderRadius: "8px",
  textAlign: "center",
  width: "300px",
};

const inputStyle = {
  width: "100%",
  padding: "10px",
  margin: "10px 0",
  borderRadius: "4px",
  border: "1px solid #ccc",
};

const buttonStyle = {
  padding: "10px 20px",
  borderRadius: "4px",
  border: "none",
  backgroundColor: "#007bff",
  color: "#fff",
  cursor: "pointer",
};

const ProjectPopUp = ({ setNoProject }) => {
  const handleSubmit = async () => {
    setNoProject(false);
    localStorage.removeItem("project")
    window.location.href = "/how-to-use";
  };

  return (
    <div style={popupOverlayStyle}>
      <div style={popupContentStyle}>
        <h3 style={{ marginBottom: "25px" }}>
          Somthing went wrong!
          <br /> The Project might have been deleted!
        </h3>
        <button onClick={handleSubmit} style={buttonStyle}>
          Proceed
        </button>
      </div>
    </div>
  );
};
