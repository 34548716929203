import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import { HiOutlinePencil, HiPlus, HiOutlineTrash } from "react-icons/hi";
import CreateProductModal from "./CreateProductModal";
import ConfirmationDialog from "../components/shared/ConfirmationDialog";
import { addEditProducts } from "../lib/api/ProductGroupHanlder";
import { toast } from "react-toastify";
const ProductsTable = ({ showProducts, products }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [removeDialogIsOpen, setRemoveDialogIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  const showRemoveDialog = () => setRemoveDialogIsOpen(true);
  const hideRemoveDialog = () => {
    setCurrentCategory(null);
    setRemoveDialogIsOpen(false);
  };

  // Transform products object into array of rows
  const data = [
    { category: "Good", ...products?.products.good },
    { category: "Better", ...products?.products.better },
    { category: "Best", ...products?.products.best },
  ];

  // Define columns based on keys in the product objects
  const columns = React.useMemo(
    () => [
      { Header: "Category", accessor: "category" },
      { Header: "Name", accessor: "name" },
      { Header: "List Price", accessor: "listPrice" },
      { Header: "Unit Cost", accessor: "unitCost" },
      { Header: "Dedicated HeadCount", accessor: "dedicatedHeadCount" },
      { Header: "Square Feet", accessor: "squareFootage" },
      { Header: "Link", accessor: "link" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) =>
          row.original.name ? (
            <>
              <div className="tooltip-container">
                <HiOutlinePencil
                  onClick={() => handleEditProduct(row.original)}
                  className="cursor-pointer mr-3 inline-flex"
                />
                <div className="tooltip-text">Edit Product</div>
              </div>
              <div className="tooltip-container">
                <HiOutlineTrash
                  onClick={() => handleRemoveProduct(row.original)}
                  className="cursor-pointer inline-flex"
                />
                <div className="tooltip-text">Delete Product</div>
              </div>
            </>
          ) : (
            <div className="tooltip-container">
              <HiPlus
                className="cursor-pointer"
                onClick={() => handleAddProduct(row.original.category)}
              />{" "}
              <div className="tooltip-text">Add Product</div>
            </div>
          ),
      },
    ],
    []
  );

  const handleAddProduct = (category) => {
    setCurrentCategory(category);
    setIsModalOpen(true);
  };

  const handleEditProduct = (original) => {
    console.log("ori ", original);
    setCurrentCategory(original.category);
    setSelectedProduct(original);
    setIsModalOpen(true);
  };

  const handleSaveProduct = (newProduct) => {
    // Handle saving the new product

    const copy = products.products;
    products.products[currentCategory.toLowerCase()] = newProduct;
    const add = addEditProducts(products.id, products.products);
    if (add.error) {
      toast.error(add.error);
      products.products = copy;
    }

    console.log("curr", products.products);
    console.log("curr..", products);
    console.log("New Product:", newProduct, "Category:", currentCategory);
    // Add logic to update the products state with the new product details
    setCurrentCategory(null);
    setSelectedProduct({});
    setIsModalOpen(false);
  };

  const handleUpdateProduct = (name, product) => {
    const copy = products.products;
    products.products[currentCategory.toLowerCase()] = product;
    const add = addEditProducts(products.id, products.products);
    if (add.error) {
      toast.error(add.error);
      products.products = copy;
    }

    products.products[currentCategory.toLowerCase()] = product;
    setCurrentCategory(null);
    setSelectedProduct({});
    setIsModalOpen(false);
  };

  const handleRemoveProductConfirmed = () => {
    if (Object.keys(currentCategory).length > 0) {
      console.log("setting empty ");
      // products.products[currentCategory] = {};

      const copy = products.products;
      products.products[currentCategory.toLowerCase()] = {};
      const add = addEditProducts(products.id, products.products);
      if (add.error) {
        toast.error(add.error);
        products.products = copy;
      }
    }
  };

  const handleRemoveProduct = (original) => {
    setCurrentCategory(original.category.toLowerCase());
    showRemoveDialog();
  };

  const handleCloseModal = () => {
    setCurrentCategory(null);
    setSelectedProduct({});
    setIsModalOpen(false);
  };

  // Use react-table hooks to get table props
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    showProducts && (
      <div className="mt-3">
        <h1 className="mb-4 text-lg font-semibold text-gray-700 mt-4 text-gray-600">
          Products
        </h1>
        <CreateProductModal
          isOpen={isModalOpen}
          onClose={() => handleCloseModal(false)}
          onSave={handleSaveProduct}
          onEdit={handleUpdateProduct}
          selectedProduct={selectedProduct}
        />
        <ConfirmationDialog
          isOpen={removeDialogIsOpen}
          onRequestClose={hideRemoveDialog}
          title="Are you sure?"
          message="Do you want to remove selected product?"
          onConfirm={handleRemoveProductConfirmed}
        />

        <table {...getTableProps()} className="w-full ">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, index) => (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    )
  );
};

export default ProductsTable;
