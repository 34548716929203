import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();

    setTimeout(() => navigate("/login"), 3000);
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h1 className="text-3xl font-extrabold text-gray-900 text-center">
            Successfully logged out!
          </h1>
          <p className="mt-2 text-sm text-gray-600 text-center">
            You'll be redirected soon
          </p>
        </div>
      </div>
    </div>
  );
};

export default Logout;
