import React, { useState, useRef, useEffect } from "react";
import { API_PATH } from "../../lib/constants";
const apiPath = API_PATH;

export default function ImageUploader({ onImageChange, imageUrl }) {
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef(null);

  useEffect(() => {
    console.log("imagurl", imageUrl);
    if (imageUrl) {
      if (
        imageUrl instanceof Object ||
        (!imageUrl.startsWith("/uploads") &&
          !imageUrl.startsWith("http") &&
          !imageUrl.startsWith("file"))
      ) {
        // const byteCharacters = atob(imageUrl);
        // const byteNumbers = new Array(byteCharacters.length);

        // for (let i = 0; i < byteCharacters.length; i++) {
        //     byteNumbers[i] = byteCharacters.charCodeAt(i);
        // }

        // const byteArray = new Uint8Array(byteNumbers);
        // const blob = new Blob([byteArray], { type: 'image/png' });
        // setImagePreviewUrl(`${URL.createObjectURL(blob)}`);

        if (!imageUrl.imageUrl) {
          imageUrl = JSON.parse(imageUrl);
        }

        const ext = imageUrl.imageUrl.endsWith(".svg")
          ? "data:image/svg+xml;base64,"
          : `data:image/${imageUrl.imageUrl.split(".").pop()};base64,`;

        setImagePreviewUrl(ext + imageUrl.imageData);

        setSelectedFile(`${imageUrl}`);
      } else {
        setImagePreviewUrl(`${apiPath}${imageUrl}`);
        setSelectedFile(`${apiPath}${imageUrl}`);
      }

      // setSelectedFile(`${apiPath}${imageUrl}`);
      // fileInputRef.current.value = imageUrl;
    }
  }, [imageUrl]);

  useEffect(() => {
    if (selectedFile) {
      onImageChange(selectedFile);
    }
  }, [selectedFile]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setImagePreviewUrl("");

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  return (
    <div className="mb-4">
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="file">
        Upload Image
      </label>
      <input
        id="file"
        type="file"
        accept=".png"
        onChange={handleFileChange}
        name="file"
        ref={fileInputRef}
        className="hidden"
      />
      <button
        type="button"
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        onClick={() => fileInputRef.current.click()}>
        Upload Image
      </button>

      {imagePreviewUrl && (
        <div className="mt-4 relative flex justify-center">
          <img
            src={imagePreviewUrl}
            alt="Preview"
            className="w-48 h-48 object-contain rounded"
          />
          <button
            type="button"
            onClick={handleRemoveImage}
            className="absolute top-0 right-0 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full focus:outline-none focus:shadow-outline">
            &times;
          </button>
        </div>
      )}
    </div>
  );
}
