import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children, states }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const [modalType, setModalType] = useState('');

  const openModal = (data, type) => {
    setModalData(data);
    setModalType(type)
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalData('');
    setModalType('');
    setIsModalOpen(false);
  };

  const contextValue = {
    isModalOpen,
    openModal,
    closeModal,
    modalData,
    modalType,
    ...states,
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
