// CreateProjectModal.js
import React, { useEffect, useState } from "react";
import Modal from "react-modal";

// Ensure the modal is attached to the app root
Modal.setAppElement("#root");

const CreateProjectModal = ({
  isOpen,
  onRequestClose,
  onCreateRequest,
  onEditRequest,
  projectData,
}) => {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [margin, setMargin] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState("");

  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  useEffect(() => {
    if (projectData) {
      setName(projectData.name || "");
      setLink(projectData.link || "");
      setPassword(projectData.password || "");
      setMargin(projectData.margin || "");
      setUser(projectData.user || "");
    }
  }, [projectData]);

  const handleCreateEditProject = (e) => {
    e.preventDefault();

    // Create project object
    const newProject = {
      name,
      link,
      margin,
      password,
      user,
    };

    // Call the appropriate callback
    if (projectData && projectData.name) {
      onEditRequest(newProject);
    } else {
      onCreateRequest(newProject);
    }

    // Reset form fields
    setName("");
    setLink("");
    setMargin("");
    setPassword("");
    setUser("");

    // Close the modal
    onRequestClose();
  };
  const handleMarginChange = (e) => {
    const value = parseFloat(e.target.value);
    if (value >= 0 && value <= 1) {
      setMargin(value);
    } else if (e.target.value === "") {
      setMargin(""); // Allow clearing the input field
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={
        projectData && projectData.name ? "Edit Project" : "Create New Project"
      }
      className="max-w-2xl w-3/4 p-6 mx-auto my-16 bg-white rounded shadow-lg"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div className="flex flex-col items-center">
        <h2 className="text-2xl w-full font-bold mb-4">
          {projectData && projectData.name
            ? "Edit Project"
            : "Create New Project"}
        </h2>
        <form onSubmit={handleCreateEditProject} className="w-full">
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name">
                Name
              </label>
              <input
                id="name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Project Name"
              />
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="link">
                Password (Optional)
              </label>
              <input
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Project Password"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="margin">
                Margin
              </label>
              <input
                id="margin"
                value={margin}
                required
                onChange={handleMarginChange}
                type="number"
                step="0.01"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Margin"
                min="0"
                max="1"
              />
            </div>
            {/* <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user">
                  User
                </label>
                <input
                  id="user"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                  type="email"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="User Email"
                />
              </div> */}
          </div>
          {/* <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Password
              </label>
              <input
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Password"
              />
            </div> */}
          <div className="flex items-center justify-end">
            <button
              type="submit"
              className="bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              {projectData && projectData.name
                ? "Edit Project"
                : "Create Project"}
            </button>

            <button
              type="button"
              onClick={onRequestClose}
              className="bg-slate-400 hover:bg-slate-500 ml-1 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateProjectModal;
