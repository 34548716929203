import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Project = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const isLoggedIn = () => {
    return (
      localStorage.getItem("isAuthenticated") &&
      localStorage.getItem("isAuthenticated") == "true"
    );
  };

  useEffect(() => {
    const setupProject = async () => {
      sessionStorage.removeItem("password");
      localStorage.removeItem("build");
      localStorage.removeItem("products");
      sessionStorage.removeItem("selectedItems");
      const link = searchParams.get("link");
      if (!link) {
        setMessage("Please select a project first");
        return;
      }
      localStorage.setItem("project", link);
      if (!isLoggedIn()) {
        setMessage(
          "You must be loggedin to make a build. If you don't have an account please create on. Redirecting to login page..."
        );
        setTimeout(() => navigate("/login"), 5000); // Redirect to login after 3 seconds
      } else {
        setMessage("Redirecting you to the build page...");

        setTimeout(() => navigate("/home/"), 3000); // Redirect to login after 3 seconds
      }
    };

    setupProject();
  }, [searchParams, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h1 className="text-3xl font-extrabold text-gray-900 text-center">
            Build Creation
          </h1>
          <p className="mt-2 text-sm text-gray-600 text-center">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default Project;
