import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useModal } from "../hooks/useModal";
import Modal from "react-modal";

Modal.setAppElement("#root");

export default function ImageModal({ isOpen }) {
  const { isModalOpen, closeModal, modalData, modalType } = useModal();
  const [showModal, setShowModal] = useState(isModalOpen);

  useEffect(() => {
    isModalOpen && modalType == "image"
      ? setShowModal(true)
      : setShowModal(false);
  }, [isModalOpen]);

  console.log("modal", modalType);

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={true}
      className="display-block modal-dialog modal-dialog-centered"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div style={{ display: "block", paddingLeft: 0 }}>
        <div className="" role="document">
          <div className="mx-auto">
            <div
              className="modal-content"
              style={{
                maxHeight: "70vh",
                maxWidth: "80vh",
                overflowY: "auto",
              }}>
              <div className="modal-query-wizard-wrapper">
                <div className="d-flex justify-content-between align-items-center modal-header-wrap">
                  <h1 className="px-10 theme-primary-color f-bold f-20">
                    {modalData?.title}
                  </h1>
                  <button
                    type="button"
                    onClick={closeModal}
                    className="close no-btn modal-close-btn"
                    data-dismiss="modal">
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/modal-close.svg`}
                      />
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <div
                    style={{
                      display: "flex",
                      "justify-content": "center",
                    }}>
                    <img src={modalData?.src} style={{maxWidth: '500px', minWidth: '500px%'}} width="100%" alt="" />
                  </div>
                  {modalData?.id && (
                    <>
                      {modalData.id.split("http")[0] != "undefined" && (
                        <>
                          <h3>
                            <strong>Description :</strong>
                          </h3>
                          <br />
                          <p>
                            {modalData.id ? modalData.id.split("http")[0] : ""}
                          </p>
                        </>
                      )}
                      <br />
                      {/* <h3>
                        <strong>Learn more :</strong>
                      </h3>
                      <br />
                      <a
                        href={`http${modalData.id.split("http")[1]}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        http{modalData?.id ? modalData.id.split("http")[1] : ""}
                      </a> */}
                    </>
                  )}
                  {Boolean(modalData?.link || false) && (
                    <>
                      <a href={encodeURI(modalData?.link)} target="_blank">(Learn more)</a>
                      
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
