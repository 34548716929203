export function convertToGroupedObject(data) {
    // Initialize an empty object to store the grouped items
    const groupedObject = {};
    const uniqueGroups = [];

    // Iterate through each item in the data array
    data.forEach((item) => {
      // Extract id and name directly
      const { id, name, ...rest } = item;

      // Iterate through the keys (0, 1, 2, etc.) to group by category
      Object.keys(rest).forEach((key) => {
        const category = rest[key].category;

        // Create the category key in groupedObject if it doesn't exist
        if (!groupedObject[category]) {
          groupedObject[category] = [];
        }

        if (uniqueGroups.includes(rest[key].name + category)) {
          rest[key].name = "";
        } else {
          uniqueGroups.push(rest[key].name + category);
        }

        // Push the item under the corresponding category
        groupedObject[category].push(rest[key]);
      });

      // Add id and name to groupedObject as-is
      // groupedObject.id = id;
      // groupedObject.name = name;
      delete groupedObject.id;
      delete groupedObject.name;
    });

    return groupedObject;
  }

export function copyToClipboard(text) {
  // Using the Clipboard API to write the text to clipboard
  return new Promise((resolve, reject) => {

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text).then(() => {
        resolve();
      }).catch((error) => {
        console.error('Failed to copy text: ', error);
        reject();
      });
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement("textarea");
      textArea.value = text;
          
      // Move textarea out of the viewport so it's not visible
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";
          
      document.body.prepend(textArea);
      textArea.select();

      try {
          document.execCommand('copy');
          resolve();
      } catch (error) {
          console.error(error);
          reject();
      } finally {
          textArea.remove();
      }
    }
  })
};

export function isValidJson(str) {
  try {
    JSON.parse(str);
    return true; // If parsing is successful, it's valid JSON
  } catch (e) {
    return false; // If an error is thrown, it's not valid JSON
  }
}
  