import React, { useEffect, useState } from "react";
import ProjectsTable from "../components/ProjectsTable";
import CreateProjectModal from "../components/CreateProjectModal";
import ConfirmationDialog from "../components/shared/ConfirmationDialog";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BuildsTable from "../components/BuildsTable";
import { getAllBuilds, deleteBuild, cloneBuild } from "../lib/api/BuildHandler";
import LoadingSpinner from "../pages/LoadingSpiner";
import { fetchProjectById } from "../lib/api/ProjectHandler";
import { fetchBuildByName } from "../lib/api/BuildHandler";
import { fetchCategoriesAndSubcategoriesByPGnames } from "../lib/api/CategoriesHandler";
export default function Builds() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [removeDialogIsOpen, setRemoveDialogIsOpen] = useState(false);
  const [cloneDialogIsOpen, setCloneDialogIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [builds, setBuilds] = useState([]);
  const [isCustomer, setIsCustomer] = useState(false);

  const [selectedBuild, setSelectedBuild] = useState({});

  const openModal = () => setModalIsOpen(true);

  const closeModal = () => {
    setSelectedBuild({});
    setModalIsOpen(false);
  };

  const showRemoveDialog = () => setRemoveDialogIsOpen(true);

  const hideRemoveDialog = () => {
    setSelectedBuild({});
    setRemoveDialogIsOpen(false);
  };

  const hideCloneDialog = () => {
    setSelectedBuild({});
    setCloneDialogIsOpen(false);
  };

  useEffect(() => {
    getAllBuilds().then((data) => setBuilds(data));
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.role == "CUSTOMER") {
      setIsCustomer(true);
    }
  }, []);

  const handleAddBuild = (newBuild) => {
    const exists = builds.some((build) => build.name == newBuild.name);
    if (!exists) {
      setBuilds((prevbuilds) => [...prevbuilds, newBuild]);
    } else {
      toast.error("Build name already exists");
    }
  };

  const handleRemoveBuild = async () => {
    const updatedBuilds = builds.filter(
      (build) => build.name !== selectedBuild.name
    );

    setBuilds(updatedBuilds);
    const removed = await deleteBuild(selectedBuild.id);
    console.log("kilo", removed);
    setSelectedBuild(null);
    toast.success("Build deleted successfully");
  };

  const handleConfirmRemoveBuild = (build) => {
    setSelectedBuild(build);
    showRemoveDialog();
  };

  const handleEditBuild = async (build) => {
    console.log("bbuild", build);
    sessionStorage.removeItem("password");

    try {
      const project = await fetchProjectById(build.project.id);
      const res = await fetchBuildByName(build.name);

      // console.log("kkkkkkk",res[0][0].selectedCats)
      const finalData = res[0];

      const pgs = [];
      for (const key of Object.keys(finalData)) {
        if (typeof finalData[key] == "object") {
          finalData[key].identified = finalData[key].name + finalData[key].tag;

          pgs.push(finalData[key].name);
        }
      }
      const ttv = await fetchCategoriesAndSubcategoriesByPGnames(pgs);
      console.log("ttv", JSON.stringify(ttv));
      const subcategoryIds = ttv.flatMap((item) =>
        item.subcategories.map((sub) => sub.id)
      );
      sessionStorage.setItem("selectedItems", JSON.stringify(subcategoryIds));

      console.log("project kk", JSON.stringify(project));
      localStorage.setItem("project", project.link);
      localStorage.setItem("build", build.name);
      localStorage.removeItem("products");
      sessionStorage.setItem(
        "selectedItems",
        JSON.stringify(res[0][0]?.selectedCats)
      );
      window.open(`/home/build`, "_blank");
    } catch {
      toast.error("Project does not exist!");
    }
  };
  const handelAddBuild = () => {
    localStorage.removeItem("build");
    localStorage.removeItem("products");
    sessionStorage.removeItem("selectedItems");
    sessionStorage.removeItem("password");
    window.location.href = "/home";
  };
  const onCloneProject = async (selectedBuild) => {
    console.log("use", selectedBuild);
    const cloned = await cloneBuild(selectedBuild.id);
    console.log("kilo", cloned);
    setSelectedBuild(null);
    toast.success("Build cloned successfully");
    window.location.reload();
  };

  // const handleEditBuild = (newBuild) => {
  //   const updatedBuilds = builds.map((build) => {
  //     if (build.name === newBuild.name) {
  //       return { ...build, ...newBuild };
  //     }
  //     return build;
  //   });

  //   setBuilds(updatedBuilds);
  // };

  return (
    <div>
      {/* <div className="flex justify-end">
        <button
          onClick={openModal}
          className="mt-4 mb-4 bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded">
          Add Build
        </button>
      </div>
      <CreateProjectModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onCreateRequest={handleAddProject}
        onEditRequest={handleEditProject}
        projectData={selectedProject}
      /> */}
      {isCustomer && (
        <div className="flex justify-end">
          <button
            onClick={handelAddBuild}
            // href="/home"
            className="mt-4 mb-4 bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded">
            Add New Build
          </button>
        </div>
      )}
      <BuildsTable
        onRemoveProject={handleConfirmRemoveBuild}
        onCloneProject={onCloneProject}
        onEditBuild={handleEditBuild}
        data={builds}
      />
      <ConfirmationDialog
        isOpen={removeDialogIsOpen}
        onRequestClose={hideRemoveDialog}
        title="Are you sure?"
        message="Do you want to remove selected build?"
        onConfirm={handleRemoveBuild}
      />
      {/* <ConfirmationDialog
        isOpen={cloneDialogIsOpen}
        onRequestClose={hideCloneDialog}
        title="Are you sure?"
        message="Do you want to clone the selected build?"
        onConfirm={onCloneProject}
      /> */}
      <ToastContainer />
    </div>
  );
}
