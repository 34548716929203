import React, { useEffect, useState, useCallback } from "react";
import CategoryBox from "./shared/CategoryBox";
import { fetchCategoriesAndSubcategories } from "../lib/api/CategoriesHandler";
import { Link, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { isValidJson } from "../lib/helpers";

export default function Specify() {
  const [subcategoryIds, setSubcategoryIds] = useState([]);
  const [allSelectedCategories, setAllSelectedCategories] = useState([]);
  const [searchParams] = useSearchParams();

  const [categories, setCategories] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  const [editBuild, setEditBuild] = useState("");

  const fetchCategoriesData = useCallback(async () => {
    const mergedData = await fetchCategoriesAndSubcategories(true);
    setCategories(mergedData);
  }, []);

  useEffect(() => {
    const dataParam = sessionStorage.getItem("selectedItems");
    console.log("dataParam", dataParam);
    setSubcategoryIds(
      dataParam && isValidJson(dataParam) ? JSON.parse(dataParam) : []
    );
    setAllSelectedCategories(
      dataParam && isValidJson(dataParam) ? JSON.parse(dataParam) : []
    );
  }, []);
  
  useEffect(() => {
    const dataParam = sessionStorage.getItem("selectedItems");
    const check =
      dataParam && isValidJson(dataParam) ? JSON.parse(dataParam) : [];
    console.log("capp " , categories)
    let allCategoryIds = [];
    categories.map((cat) => {
      cat.subcategories.map((subcat) => allCategoryIds.push(subcat.id));
    });
    if (allCategoryIds.length == check.length && check.length > 0) {
      setIsSelected(true);
    }
  }, [categories]);

  useEffect(() => {
    fetchCategoriesData();
  }, [fetchCategoriesData]);

  useEffect(() => {
    if (isSelected) {
      let allCategoryIds = [];
      categories.map((cat) => {
        cat.subcategories.map((subcat) => allCategoryIds.push(subcat.id));
      });
      addUniqueCategories(allCategoryIds);
      setSubcategoryIds(allCategoryIds);
    } else {
      addUniqueCategories([]);
    }
  }, [isSelected, categories]);

  useEffect(() => {
    if(categories && categories.length > 0 && !sessionStorage.getItem('selectedItems')){
      setTimeout(() => {
      selectAll(true)}, 500);
    }
    
  }, [categories]);

  const selectAll = (value) => {
    setIsSelected(value);
    if (!value) {
      setAllSelectedCategories([]);
      setSubcategoryIds([]);
    }
  };
  const handleContinue = () => {
    sessionStorage.setItem(
      "selectedItems",
      JSON.stringify(allSelectedCategories)
    );
    window.location.href = `/home/build`;

  };
  const addUniqueCategories = (newCategoryIds) => {
    setAllSelectedCategories((prevArray) => {
      // Combine previous array and new category IDs
      const combinedArray = [...prevArray, ...newCategoryIds];
      // Use a Set to remove duplicates
      const uniqueCategories = [...new Set(combinedArray)];
      return uniqueCategories;
    });
  };
  const addCategory = (id) => {
    setAllSelectedCategories((prev) => [...prev, id]);
  };

  const removeCategory = (id) => {
    const allCats = allSelectedCategories.filter((c) => c !== id);
    setAllSelectedCategories(allCats);
    setSubcategoryIds(allCats);
  };

  return (
    <div className="row m-0">
      <div className="col-lg-12">
        {/* <section id="content">
          <div className="section-wrapper">
            <div className="section-wrapper"> */}
        <div className="tab-wrap">
          <a
            className="nav-item nav-link active-tab"
            id="nav-home-tab"
            data-toggle="tab"
            href="#nav-home"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true">
            <img src={"/assets/images/specify.png"} />
            Specify
          </a>

          {allSelectedCategories && allSelectedCategories.length > 0 && (
            <Link onClick={handleContinue} className="nav-item nav-link">
              <img src={"/assets/images/build-icon.png"} />
              Build
            </Link>
          )}
          {(!allSelectedCategories || allSelectedCategories.length === 0) && (
            <a
              className="nav-item nav-link"
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
              style={{ cursor: "default", pointerEvents: "none" }}>
              <img src={"/assets/images/build-icon.png"} />
              Build
            </a>
          )}
          <a
            className="nav-item nav-link"
            id="nav-contact-tab"
            data-toggle="tab"
            href="#nav-comments"
            role="tab"
            aria-controls="nav-contact"
            aria-selected="false"
            style={{ cursor: "default", pointerEvents: "none" }}>
            <img src={"/assets/images/download-icon.png"} /> Report
          </a>
        </div>
        <div className="p-2 mt-3 text-center f-20 f-color-b col-lg-6 col-sm-12 mx-auto d-flex justify-content-center align-items-center">
          Use the check boxes to specify which areas of the floor plate you'd
          like to budget and plan for.
        </div>
        <div className="chkbox-wrap container mt-4 pt-4">
          <div
            className="d-flex align-items-center mb-4"
            style={{ justifyContent: "center" }}>
            <label className="label-container">
              <p style={{ color: "#C8102E", fontWeight: "1000" }}>Select All</p>
              <input
                type="checkbox"
                checked={isSelected}
                onChange={(e) => selectAll(e.target.checked)}
              />
              <span className="checkmark" />
            </label>
          </div>
          <div className="chkbox-list-wrap d-grid flex-wrap">
            {/* {console.log("setIsSelected ===>", isSelected)}
                  {console.log("allSelectedCategories ===>", allSelectedCategories)}
                  {console.log("categories ===>", categories)}
                  {console.log("subcategoryIds ===>", subcategoryIds)} */}

            {categories.map((cat) => (
              <CategoryBox
                key={cat.id}
                title={cat.name}
                subCategories={cat.subcategories}
                allSelected={isSelected}
                addSelectionHandler={addCategory}
                removeSelectionHanlder={removeCategory}
                selected={subcategoryIds}
              />
            ))}
          </div>
          <div className="btn-wrap text-center p-5">
            {console.log(
              "allSelectedCategories ===>",
              allSelectedCategories.length
            )}
            {console.log("subcategoryIds ===>", subcategoryIds.length)}
            {(allSelectedCategories.length > 0 ||
              subcategoryIds.length > 0) && (
              <Link
              // to={`/home/build?data=${JSON.stringify(
              //   allSelectedCategories
              // )}${editBuild}`}>
              >
                <button
                  onClick={handleContinue}
                  type="button"
                  data-toggle="modal"
                  data-target="#query-wizard-expression-modal"
                  className="btn-round">
                  Continue
                </button>
              </Link>
            )}
          </div>
        </div>
        {/* </div>{" "}
          </div>
        </section> */}
      </div>
    </div>
  );
}
