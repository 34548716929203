export const fetchWithToken = async (url, options = {}) => {
    // Retrieve JWT token from localStorage
    const token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : '';
  
    // Default headers for Authorization
    const headers = {
      // 'Content-Type': 'application/json',
      // Include the JWT token in Authorization header
      Authorization: `Bearer ${token}`,
      ...options.headers // Merge with any additional headers passed
    };
  

    console.log('token', token)
    // Configure fetch options
    const fetchOptions = {
      ...options,
      headers
    };
  
    try {
      const response = await fetch(url, fetchOptions);
      return response;
    //   if (!response.ok) {
    //     throw new Error('Network response was not ok.');
    //   }
    //   return await response.json();
    } catch (error) {
      console.error('Fetch Error:', error);
      throw error;
    }
  };