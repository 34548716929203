import React, { useEffect, useState } from "react";
import ProjectsTable from "../components/ProjectsTable";
import CreateProjectModal from "../components/CreateProjectModal";
import ConfirmationDialog from "../components/shared/ConfirmationDialog";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addProject,
  cloneProject,
  deleteProject,
  editProject,
  fetchProjects,
} from "../lib/api/ProjectHandler";

export default function Projects() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [removeDialogIsOpen, setRemoveDialogIsOpen] = useState(false);

  const [projects, setProjects] = useState([]);

  const [selectedProject, setSelectedProject] = useState({});

  const [reload, setReload] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setSelectedProject({});
    setModalIsOpen(false);
  };

  const showRemoveDialog = () => setRemoveDialogIsOpen(true);
  const hideRemoveDialog = () => {
    setSelectedProject({});
    setRemoveDialogIsOpen(false);
  };

  useEffect(() => {
    fetchProjects().then((data) => setProjects(data));
  }, []);

  const handleAddProject = (newProject) => {
    const exists = projects.some((project) => project.name == newProject.name);
    if (!exists) {
      addProject(newProject).then((data) => {
        if (data.error) {
          toast.error("error while creating project");
        } else {
          newProject.link = data.project?.link;
          setProjects((prevProjects) => [...prevProjects, newProject]);
        }
        window.location.reload()

      });
    } else {
      toast.error("Project email already exists");
    }
  };

  const handleRemoveProject = () => {
    const deleted = deleteProject(selectedProject.id);
    if (deleted.error) {
      toast.error(deleted.error);
      return;
    }
    const updatedProjects = projects.filter(
      (Project) => Project.name !== selectedProject.name
    );

    setProjects(updatedProjects);

    setSelectedProject(null);
  };

  const handleConfirmRemoveProject = (project) => {
    setSelectedProject(project);
    showRemoveDialog();
  };

  const handleEditProjectModal = (project) => {
    console.log("use ", project);
    setSelectedProject(project);
    openModal();
  };

  const hanldeCloneProject = (project) => {
    setReload((prev) => !prev);
    cloneProject(project.id);
    fetchProjects().then((data) => {setProjects(data) 
      window.location.reload()});
    
  };

  const handleEditProject = (newProject) => {
    console.log('edit====', newProject)
    const edited = editProject(projects.find(p => p.link === newProject.link).id, {name: newProject.name, margin: newProject.margin, password: newProject.password});
    if(edited.error){
      toast.error(edited.error);
      return;
    }
    const updatedProjects = projects.map((project) => {
      if (project.link === newProject.link) {
        return { ...project, ...newProject };
      }
      return project;
    });

    setProjects(updatedProjects);
  };

  return (
    <div>
      <div className="flex justify-end">
        <button
          onClick={openModal}
          className="mt-4 mb-4 bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded">
          Add Project
        </button>
      </div>
      <CreateProjectModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onCreateRequest={handleAddProject}
        onEditRequest={handleEditProject}
        projectData={selectedProject}
      />
      <ProjectsTable
        onRemoveProject={handleConfirmRemoveProject}
        onEditProject={handleEditProjectModal}
        onCloneProject={hanldeCloneProject}
        data={projects}
      />
      <ConfirmationDialog
        isOpen={removeDialogIsOpen}
        onRequestClose={hideRemoveDialog}
        title="Are you sure?"
        message="Do you want to remove selected project?"
        onConfirm={handleRemoveProject}
      />
      <ToastContainer />
    </div>
  );
}
