import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { HiOutlinePencil, HiOutlineTrash, HiDuplicate, HiOutlineClipboard } from "react-icons/hi";
import { HOST_NAME } from "../lib/constants";
import { copyToClipboard } from "../lib/helpers";
import { toast } from "react-toastify";

let columns = [
  { Header: "Name", accessor: "name" },
  { Header: "Link", accessor: "link" },
  { Header: "Margin", accessor: "margin" },
  { Header: "Password", accessor: "password" },
  { Header: "Created By", accessor: "createdBy.username" },
  { Header: "Date Created", accessor: "createdAt" },
  { Header: "Action", accessor: "icon" },
];

function ProjectsTable({
  data,
  onRemoveProject,
  onEditProject,
  onCloneProject,
}) {
  const [pageSize, setPageSize] = useState(30);
  const [pageIndex, setPageIndex] = useState(0);

  const handleRedirect = (link) => {
    localStorage.removeItem("build");
    localStorage.removeItem("products");
    sessionStorage.removeItem("selectedItems");
    window.open(`${HOST_NAME}/project?link=${link}`, "_blank");
  };

  function Actions({ row }) {
    return (
      <div style={{whiteSpace: 'nowrap'}}>
        <div className="tooltip-container">
          <HiOutlinePencil
            onClick={() => onEditProject(row)}
            className="cursor-pointer mr-3 inline-flex"
          />
          <div className="tooltip-text">Edit Project</div>
        </div>
        <div className="tooltip-container">
          <HiOutlineTrash
            onClick={() => onRemoveProject(row)}
            className="cursor-pointer mr-3 inline-flex"
          />
          <div className="tooltip-text">Delete Project</div>
        </div>
        <div className="tooltip-container">
          <HiDuplicate
            onClick={() => onCloneProject(row)}
            className="cursor-pointer inline-flex"
          />
          <div className="tooltip-text">Clone Project</div>
        </div>
      </div>
    );
  }

  const handleRows = (cell, row) => {
    if (cell.column.id === "icon") {
      return <Actions row={row.original} />;
    } else if (cell.column.id === "link") {
      const link = cell.value;
      return (
        <div className="d-flex">
          <div className="tooltip-container pr-2">
            <HiOutlineClipboard
              onClick={() => copyProjectLink(link)}
              className="cursor-pointer inline-flex"
            />
            <div className="tooltip-text">Copy Link</div>
          </div>
          <Link onClick={() => handleRedirect(link)} rel="noopener noreferrer">
            <p>{`${HOST_NAME}/project?link=${link}`}</p>
          </Link>
        </div>
      );
    } else if(cell.column.id === "createdAt") {
      
      const date = new Date(cell.value);
      // Extract date components
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      
      // Extract time components
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      
      // Format as "MM-DD-YYYY at HH:MM"
      return `${month}-${day}-${year} at ${hours}:${minutes}`;
    } else {
      return cell.render("Cell");
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  const pageCount = Math.ceil(rows.length / pageSize);
  const paginatedRows = rows.slice(
    pageIndex * pageSize,
    (pageIndex + 1) * pageSize
  );

  const copyProjectLink = (link) => {
    copyToClipboard(`${HOST_NAME}/project?link=${link}`)
      .then(() => {
        toast.success("Link copied to clipboard");
      })
      .catch((e) => {
        console.error("Error while copying link: ", e);
        toast.error("Failed to copy link to clipboard");
      });
  };

  return (
    <>
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key="1"
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {paginatedRows.map((row) => {
            prepareRow(row);
            return (
              <tr key={row.original.name} {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{handleRows(cell, row)}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination controls */}
      <div className="flex justify-end mt-3">
        <div className="text-gray-700 mr-3 mt-1">
          Page {pageIndex + 1} of {pageCount}
        </div>
        <button
          onClick={() => setPageIndex(0)}
          disabled={pageIndex === 0}
          className={`border border-gray-300 rounded px-3 py-1 ${
            pageIndex === 0
              ? "bg-red-800 text-white cursor-not-allowed"
              : "bg-red-900 text-white hover:bg-red-950"
          }`}>
          {"<<"}
        </button>
        <button
          onClick={() => setPageIndex(pageIndex - 1)}
          disabled={pageIndex === 0}
          className={`border border-gray-300 rounded px-3 py-1 ${
            pageIndex === 0
              ? "bg-red-800 text-white cursor-not-allowed"
              : "bg-red-900 text-white hover:bg-red-950"
          }`}>
          {"<"}
        </button>
        <button
          onClick={() => setPageIndex(pageIndex + 1)}
          disabled={pageIndex >= pageCount - 1}
          className={`border border-gray-300 rounded px-3 py-1 ${
            pageIndex >= pageCount - 1
              ? "bg-red-800 text-white cursor-not-allowed"
              : "bg-red-900 text-white hover:bg-red-950"
          }`}>
          {">"}
        </button>
        <button
          onClick={() => setPageIndex(pageCount - 1)}
          disabled={pageIndex >= pageCount - 1}
          className={`border border-gray-300 rounded px-3 py-1 ${
            pageIndex >= pageCount - 1
              ? "bg-red-800 text-white cursor-not-allowed"
              : "bg-red-900 text-white hover:bg-red-950"
          }`}>
          {">>"}
        </button>
      </div>
    </>
  );
}

export default ProjectsTable;
