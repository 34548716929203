import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_PATH } from '../lib/constants';


const Confirm = () => {
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
  
    useEffect(() => {
      const confirmAccount = async () => {
        const code = searchParams.get('code');
        const email = searchParams.get('email');
        if (!code) {
          setMessage('Invalid confirmation code.');
          return;
        }
        try {
            const response = await fetch(`${API_PATH}/confirm?code=${code}&email=${email}`);
            const data = await response.json();
    
            if (response.ok) {
              setMessage(data.message);
              setTimeout(() => navigate('/login'), 3000); // Redirect to login after 3 seconds
            } else {
              setMessage(data.error);
            }
          } catch (error) {
            setMessage('An error occurred. Please try again later.');
          }
        };
    
        confirmAccount();
      }, [searchParams, navigate]);


      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h1 className="text-3xl font-extrabold text-gray-900 text-center">Account Confirmation</h1>
          <p className="mt-2 text-sm text-gray-600 text-center">{message}</p>
          
        </div>
      </div>
    </div>
      );
};

export default Confirm;
