import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import "./Tooltip.css"; // Import the CSS file where your tooltip styles are defined

import {
  HiOutlinePencil,
  HiOutlineTrash,
  HiEyeOff,
  HiArrowUp,
  HiArrowDown,
} from "react-icons/hi";

function CategoriesTable({
  data,
  onRemoveCategory,
  onEditCategory,
  onHideCategory,
  onEditSubCategory,
  onRemoveSubCategory,
  columns,
  catModal,
  parentCategory,
  isSub,
  setpgName,
}) {
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  useEffect(() => {
    // Initialize Bootstrap tooltips
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipTriggerList.forEach(tooltipTriggerEl => {
      new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);
  function Actions({ row }) {
    return (
      <>
        <div className="tooltip-container">
          <HiOutlinePencil
            onClick={() => onEditCategory(row, parentCategory)}
            className="cursor-pointer mr-3 inline-flex"
          />
          <div className="tooltip-text">Edit Category</div>
        </div>

        <div className="tooltip-container">
          <HiOutlineTrash
            onClick={() => onRemoveCategory(row, parentCategory)}
            className="cursor-pointer mr-3 inline-flex"
          />
          <div className="tooltip-text">Remove Category</div>
        </div>

        <div className="tooltip-container">
          <HiEyeOff
            onClick={() => onHideCategory(row, parentCategory)}
            className="cursor-pointer mr-3 inline-flex"
          />
          <div className="tooltip-text">Hide Category</div>
        </div>
      </>
    );
  }

  const handleCheckboxChange = (id) => {
    if(isSub){
      setpgName('')
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>",isSub)
    }
    if (selectedCheckbox == id) {
      setSelectedCheckbox(null);
    } else {
      setSelectedCheckbox(id);
    }
  };

  function PopulateRow({ row }) {
    // console.log("orig", row.row.original);
    if (row.column.id === "icon") {
      return <Actions row={row.row.original} />;
    } else if (row.column.id === "select") {
      return (
        <input
          type="checkbox"
          checked={selectedCheckbox === row.row.original.id}
          onChange={() => handleCheckboxChange(row.row.original.id)}
        />
      );
    } else if (row.column.id === "hidden") {
      // console.log("hidd", row.value);
      return row.value ? "Yes" : "No";
    } else {
      return row.value.length > 60
        ? `${row.value.substring(0, 60)}...`
        : row.render("Cell");
    }
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  return (
    <>
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key="1"
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{<PopulateRow row={cell} />}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Subcategories
        catModalHanlder={catModal}
        showCategories={selectedCheckbox}
        subdata={data.find((x) => x.id == selectedCheckbox)}
        onEditSubCategory={onEditSubCategory}
        onRemoveSubCategory={onRemoveSubCategory}
        onHideCategory={onHideCategory}
        isSub={isSub}
        setpgName={setpgName}
      />
    </>
  );
}

const Subcategories = ({
  showCategories,
  subdata,
  catModalHanlder,
  onEditSubCategory,
  onRemoveSubCategory,
  onHideCategory,
  isSub,
  setpgName,
}) => {
  const data = subdata?.subcategories ? subdata.subcategories : [];

  const headers = [
    { Header: "Select", accessor: "select" },
    { Header: "Name", accessor: "name" },
    { Header: "Description", accessor: "text" },
    { Header: "Product Group", accessor: "productGroup" },
    { Header: "Hidden", accessor: "hidden" },
    { Header: "Action", accessor: "icon" },
  ];
  return showCategories ? (
    <>
      {isSub ? (
        <>
          <h1 className="mb-4 text-lg font-semibold text-gray-700 mt-4 text-gray-600">
            Subcategories:{" "}
          </h1>
          <div className="flex justify-end">
            <button
              onClick={() => catModalHanlder(subdata.name)}
              className="mt-4 mb-4 bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded">
              Add Sub-Category
            </button>
          </div>

          <CategoriesTable
            onEditCategory={onEditSubCategory}
            onRemoveCategory={onRemoveSubCategory}
            onHideCategory={onHideCategory}
            data={data}
            columns={headers}
            parentCategory={subdata.name}
            isSub={false}
            setpgName={setpgName}
          />
        </>
      ) : (
        subdata && subdata.productGroup && setpgName(subdata.productGroup)
      )}
    </>
  ) : null;
};

export default CategoriesTable;
