import React from "react";

const HowToUse = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f4f8",
        padding: "20px",
      }}
    >
      <div
        style={{
          backgroundColor: "#ffffff",
          border: "1px solid #d1d5db",
          borderRadius: "8px",
          
          padding: "32px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h1 style={{ color: "#0f4761", fontFamily: "Play", fontSize: "20pt", marginBottom: "16px" }}>
          HOW TO USE THIS TOOL
        </h1>

        <p style={{ fontFamily: "Aptos", fontSize: "12pt", marginBottom: "1em" }}>
          Thank you for your interest in our Budget Builder. This page contains information on how to use the tool. For
          more information,{" "}
          <a
            href="https://www.bos.com/connect/"
            style={{ color: "#467886", textDecoration: "underline" }}
            target="_blank"
          >
            CONNECT
          </a>{" "}
          with us here or visit{" "}
          <a
            href="https://www.bos.com/budgeting"
            style={{ color: "#467886", textDecoration: "underline" }}
            target="_blank"
          >
            www.bos.com/budgeting
          </a>{" "}
          for instructions.
        </p>

        <h2 style={{ color: "#0f4761", fontFamily: "Play", fontSize: "16pt", marginBottom: "16px" }}>
          REQUIREMENTS
        </h2>

        <ol style={{ listStyleType: "decimal", paddingInlineStart: "36px", marginBottom: "1em" }}>
          <li>
            <strong>User Account</strong>. Login by creating a free account or using valid user credentials. Use the
            ‘forgot password’ link on the login screen or connect with your account manager for assistance accessing the
            tool.
          </li>
          <li>
            <strong>Project Link</strong>. Your account manager will provide a unique project link for you to build a
            budget with.
            <ol style={{ listStyleType: "lower-alpha", paddingInlineStart: "72px" }}>
              <li>Without a project link, you will be sent to this page.</li>
              <li>
                Every build you save will be associated with the project link it was built from. Cloning or ‘saving as’
                to create a new build will associate new builds with the original project link.
              </li>
              <li>
                If you receive multiple project links, be intentional about the one you wish to associate a build with
                by clicking on it or pasting it into your browser before building.
              </li>
              <li>
                If you do not have a project link or have questions about their use, reach out to your account manager
                or{" "}
                <a
                  href="https://www.bos.com/connect/"
                  style={{ color: "#467886", textDecoration: "underline" }}
                  target="_blank"
                >
                  CONNECT
                </a>{" "}
                with us here.
              </li>
            </ol>
          </li>
        </ol>

        <h2 style={{ color: "#0f4761", fontFamily: "Play", fontSize: "16pt", marginBottom: "16px" }}>
          TERMS TO KNOW
        </h2>

        <p style={{ fontFamily: "Aptos", fontSize: "12pt", marginBottom: "0" }}>
          <strong style={{ fontStyle: "italic" }}>Budget Builder –</strong> This tool, which can be used to quickly
          budget and program a modern workspace.
        </p>

        <p style={{ fontFamily: "Aptos", fontSize: "12pt", marginBottom: "0" }}>
          <strong style={{ fontStyle: "italic" }}>User – </strong> Your unique user account with a user name, password and associated email. 
          Emails from this tool will come from <a
                  href="mailto:budget@bos.com"
                  style={{ color: "#467886", textDecoration: "underline" }}
                  target="_blank"
                >budget@bos.com</a>
        </p>

        <p style={{ fontFamily: "Aptos", fontSize: "12pt", marginBottom: "0" }}>
          <strong style={{ fontStyle: "italic" }}>Project – </strong> A unique link provided by your account manager that is required to start a build in this tool
        </p>

        <p style={{ fontFamily: "Aptos", fontSize: "12pt", marginBottom: "0" }}>
          <strong style={{ fontStyle: "italic" }}>Build – </strong> Using a project link, users can specify and build a budget using the tool. Builds can be saved, edited, cloned and shared. They can also be turned into reports.
        </p>

        <p style={{ fontFamily: "Aptos", fontSize: "12pt", marginBottom: "0" }}>
          <strong style={{ fontStyle: "italic" }}>Report – </strong> A summary of a build that can be downloaded as a PDF, shared or edited.
        </p>

        <h2 style={{ color: "#0f4761", fontFamily: "Play", fontSize: "16pt", marginBottom: "16px", marginTop: "10px" }}>
          NEXT STEPS AND MORE INFORMATION
        </h2>

        <p style={{ fontFamily: "Aptos", fontSize: "12pt", marginBottom: "0" }}>
          If you have a user account and project link, feel free to use this tool. If you require assistance or want more information, 
          CONNECT with us here or visit <a
                  href="https://www.bos.com/budgeting"
                  style={{ color: "#467886", textDecoration: "underline" }}
                  target="_blank"
                > www.bos.com/budgeting</a> for instructions.
        </p>

        <p style={{ fontFamily: "Aptos", fontSize: "12pt", marginBottom: "0" }}>
          Please note that pricing on this tool for estimating and budgeting only. Pricing is not guaranteed nor is it contractual. 
          Please visit <a
                  href="https://www.bos.com/budgeting"
                  style={{ color: "#467886", textDecoration: "underline" }}
                  target="_blank"
                >www.bos.com/budgeting</a> for more details.
        </p>
        
      </div>
    </div>
  );
};

export default HowToUse;
