import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ element: Component }) => {
    const projectInLocalStorage = localStorage.getItem('project') && localStorage.getItem('project') !== 'undefined';
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectInQuery = searchParams.get('project');
    
    
    if (projectInLocalStorage || projectInQuery) {
        return <Outlet />;
      } else {
        return <Navigate to="/how-to-use" />;
      }
};

export default ProtectedRoute;
