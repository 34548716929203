
import { API_PATH } from '../constants';
import { fetchWithToken } from '../Fetcher';

const apiPath = API_PATH;
export const fetchProductGroupsAndProducts = async () => {
    try {
      const productGroupRes = await 
        fetchWithToken(`${apiPath}/api/productGroups`);
  
      const productGroups = await productGroupRes.json();
  
      return productGroups;
    } catch (error) {
      console.error('Error fetching data', error);
      return [];
    }
  };

  export const editProductGroups = async (id, productGroup) => {
    try {
      const productGroupEdited =
        await fetchWithToken(`${apiPath}/api/productGroups/${id}`, {
          method: "PUT",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify(productGroup)
      });
  
      if (!productGroupEdited.ok) {
        return {error: 'error'}
      }
      const edited = await productGroupEdited.json();

      if(edited.message !== 'success'){
        return {error: edited.message};
      }
      
      return edited;
    }catch(error){
     return { error: error.message }
    }
  };

  export const addEditProducts = async (id, products) => {
    try {
      const productGroupEdited =
        await fetchWithToken(`${apiPath}/api/productGroups/${id}/products`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify({products: {...products}})
      });
  
      if (!productGroupEdited.ok) {
        return {error: 'error'}
      }
      const edited = await productGroupEdited.json();

      if(edited.message !== 'success'){
        return {error: edited.message};
      }
      
      return edited;
    }catch(error){
     return { error: error.message }
    }
  };