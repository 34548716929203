import React, { useState } from 'react';
import { register } from '../lib/api/UserHandler';

import { FaEyeSlash, FaEye } from 'react-icons/fa'

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        
        const data = await register(username, email, password);
        
        if (data.error) {
          
          setError(data.error);
          setSuccess('');
          
        } else {
          setError('');
          setSuccess("We have sent and email for details to confirm your account")
        }
      } catch (error) {
        setError('Error during login. Please try again.');
        setSuccess('');
      }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={process.env.PUBLIC_URL + "/assets/images/bos-logo.png" } alt="bos.com" />
        <h2 className="mt-4 text-center text-3xl font-extrabold text-gray-900">Create an account</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                Username
              </label>
              <div className="mt-1">
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  className="w-full p-3 mt-1 border border-gray-300 rounded-lg pr-10"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="w-full p-3 mt-1 border border-gray-300 rounded-lg pr-10"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                className="w-full p-3 mt-1 border border-gray-300 rounded-lg pr-10"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-700"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash className="w-5 h-5" /> : <FaEye className="w-5 h-5" />}
              </button>
            </div>
            </div>

            {error && (
              <p className="mt-2 text-sm text-red-600" id="error-message">
                {error}
              </p>
            )}

            {success && (
              <p className="mt-2 text-sm text-green-600" id="success-message">
                {success}
              </p>
            )}  

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <a href="/login" className="font-medium text-slate-500 hover:text-slate-600">
                  Login
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full bg-red-900 hover:bg-red-950  py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
