import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login, resendEmail } from "../lib/api/UserHandler";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!recaptchaToken) {
      setError("Please complete the reCAPTCHA");
      return;
    }
    try {
      const response = await login(username, password, recaptchaToken);
      console.log("handleSubmit", response);
      if (response.token) {
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem(
          "user",
          JSON.stringify({
            token: response.token,
            username: username,
            role: response.role,
          })
        );
        setIsAuthenticated(true);
      } else {
        window.grecaptcha.reset();
        console.log("ress", response);
        if (response.error) {
          if(response.error.toLowerCase() == 'internal server error'){
            setError("Invalid username or password");
          }else{
            setError(response.error);
          }
        } else {
          setError("Invalid username or password");
        }
      }
    } catch (error) {
      setError("Error during login. Please try again.");
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      if (user.role === "ADMIN" || user.role === "TEAM_MEMBER") {
        window.location.href = "/";
      } else if (user.role === "CUSTOMER") {
        window.location.href = "/home";
      }
    }
  }, [isAuthenticated]);

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const  handleResendClick = async() => {
    const result = await resendEmail(username);
    setError(result);
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={process.env.PUBLIC_URL + "/assets/images/bos-logo.png"}
          alt="bos.com"
        />
        <h2 className="mt-4 text-center text-3xl font-extrabold text-gray-900">
          Login to your account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700">
                Username
              </label>
              <div className="mt-1">
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  className="w-full p-3 mt-1 border border-gray-300 rounded-lg pr-10"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="w-full p-3 mt-1 border border-gray-300 rounded-lg pr-10"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-700"
                  onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <FaEyeSlash className="w-5 h-5" />
                  ) : (
                    <FaEye className="w-5 h-5" />
                  )}
                </button>
              </div>
            </div>

            <ReCAPTCHA
              sitekey="6LdX6gwqAAAAAKCqdbr5pS1YUTdF8plt5VOkorkA"
              onChange={handleRecaptchaChange}
            />

            {error && (
            <p className="mt-2 text-sm text-red-600" id="error-message">
              {error}
              {error.includes("Account not activated") && (
                <span
                onClick={handleResendClick}
                className="ml-1 text-sm text-red-600 underline cursor-pointer"
                >
                  
                  Resend Activation
                </span>
              )}
            </p>
            )}

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <a
                  href="/forgotpassword"
                  className="font-medium text-slate-500 hover:text-slate-600">
                  Forgot your password?
                </a>
              </div>
            </div>
            <p className="text-xs">OR</p>
            <div className="flex items-center justify-between">
              <div className="text-sm">
                <a
                  href="/register"
                  className="font-medium text-slate-500 hover:text-slate-600">
                  Create an account
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full bg-red-900 hover:bg-red-950 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
