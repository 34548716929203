import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { HiOutlineBadgeCheck, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";

const columns = [
  { Header: "Name", accessor: "username" },
  { Header: "Role", accessor: "role" },
  { Header: "Email", accessor: "email" },
  { Header: "Action", accessor: "icon" },
];

function UsersTable({ data, onRemoveUser, onEditUser, onApproveUser }) {
  console.log("dddddd", data);
  function Actions({ row }) {
    return (
      <>
        {" "}
        <div className="tooltip-container">
          <HiOutlinePencil
            onClick={() => onEditUser(row)}
            className="cursor-pointer mr-3 inline-flex"
          />

          <div className="tooltip-text">Edit User</div>
        </div>
        <div className="tooltip-container">
          <HiOutlineTrash
            onClick={() => onRemoveUser(row)}
            className="cursor-pointer mr-3 inline-flex"
          />
          <div className="tooltip-text">Delete User</div>
        </div>
        <div className="tooltip-container">
          <HiOutlineBadgeCheck
            onClick={() => onApproveUser(row)}
            className="cursor-pointer inline-flex"
          />
          <div className="tooltip-text">Approve User</div>
        </div>
      </>
    );
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  return (
    <>
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key="1"
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr key={row.original.name} {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>
                    {cell.column.id === "icon" ? (
                      <Actions row={row.original} />
                    ) : (
                      cell.render("Cell")
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default UsersTable;
