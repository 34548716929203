import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useModal } from "../hooks/useModal";

import { saveNewBuild, updateBuild } from "../../lib/api/BuildHandler";
import { toast } from "react-toastify";
import LoadingSpinner from "../../pages/LoadingSpiner";

import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import { fetchWithToken } from "../../lib/Fetcher";
import { API_PATH } from "../../lib/constants";

Modal.setAppElement("#root");

export default function NewBuildModal({ isOpen }) {
  const [isLoading, setIsLoading] = useState(false);
  const { isModalOpen, closeModal, modalData, modalType } = useModal();
  const [showModal, setShowModal] = useState(isModalOpen);
  const [name, setName] = useState("");
  const [lastSave, setLastSave] = useState("");
  const [nameAlreadyExists, setNameAlreadyExists] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [nameError, setNameError] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (isModalOpen && modalType === "new-build") {
      setName(
        localStorage.getItem("products") ? localStorage.getItem("build") : ""
      );
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [isModalOpen]);

  useEffect(() => {
    setLastSave(
      localStorage.getItem("build") ? localStorage.getItem("build") : ""
    );
  }, []);

  useEffect(() => {
    if (modalData?.lastSave) {
      setLastSave(modalData.lastSave);
      setName(modalData.lastSave);
    }
  }, [modalData.lastSave]);

  const handleSaveNewBuild = async () => {
    setDisableSave(true);
    if (
      localStorage.getItem("products") &&
      localStorage.getItem("products").length > 0
    ) {
      console.log("tfd");
      localStorage.setItem("build", name);
    } else {
      toast.error("Products not found");
      closeModal();
      setDisableSave(false);
      return;
    }
    const projectName = localStorage.getItem("project");
    console.log("tfd", projectName);

    if (!projectName) {
      toast.error("Please select a project first");
      closeModal();
      setDisableSave(false);
      return;
    }
    try {
      const response = await saveNewBuild(name, projectName);

      if (response?.error) {
        toast.error(response.error);
      } else {
        toast.info("Successfully saved build");
        setLastSave(name);
        closeModal();
        modalData?.successcb();
      }
    } catch (error) {
      console.error("Error while saving new build:", error);
      toast.error("Something went wrong! Please try again.");
    }
    setDisableSave(false);
  };

  const handleSaveBuild = async () => {
    setDisableSave(true);
    if (
      localStorage.getItem("products") &&
      localStorage.getItem("products").length > 0
    ) {
      localStorage.setItem("build", name);
    } else {
      toast.error("Products not found");
      closeModal();
      return;
    }
    const projectName = localStorage.getItem("project");
    if (!projectName) {
      toast.error("Please select a project first");
      closeModal();
      return;
    }
    try {
      const response = await updateBuild(name, projectName);

      if (response?.error) {
        toast.error(response.error);
      } else {
        toast.info("Successfully saved build");
        setLastSave(name);
        closeModal();
        modalData?.successcb();
      }
    } catch (error) {
      console.error("Error while saving new build:", error);
      toast.error("Something went wrong! Please try again.");
    }
    setDisableSave(false);
  };

  const setBuildNameAndCheckValidity = async (e) => {
    setNameError(null);
    setNameAlreadyExists(false);

    const name = e.target.value; // Get the name from the input field

    setName(name);

    if (
      localStorage.getItem("build") &&
      localStorage.getItem("build").toLowerCase() === name.toLowerCase()
    ) {
      setDisableSave(false);
      return;
    }

    setDisableSave(true);
    if (name) {
      try {
        // Fetch request
        const response = await fetchWithToken(
          `${API_PATH}/api/builds?name=${name}`
        );
        let data = await response.json(); // Parse response to JSON

        data =
          Array.isArray(data) && data[0] !== null && data[0] !== undefined
            ? data[0]
            : [];

        // Check if name already exists
        const exists = data.length > 0;
        setNameAlreadyExists(exists); // Set the state based on the result

        if (exists) {
          toast.error(
            "A build with this name already exists. Please choose a different name."
          );
          setDisableSave(true);
          setNameError(
            "A build with this name already exists. Please choose a different name."
          );
        } else {
          setDisableSave(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(
          "There was an error while checking if a build with this name exists."
        );
        setDisableSave(true);
      }
    }
  };

  return (
    showModal && (
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        className="fixed inset-0 flex items-center justify-center p-4"
        overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
        <div
          className="bg-white rounded-lg shadow-lg w-full max-w-2xl  max-h-[80vh] overflow-y-auto"
          role="document">
          <div className="mx-auto">
            <div className="modal-content">
              <div className="modal-query-wizard-wrapper">
                <div className="d-flex justify-content-between align-items-center modal-header-wrap">
                  <h1 className="theme-primary-color f-bold f-20">
                    Save Build
                  </h1>
                  <button
                    type="button"
                    className="close no-btn modal-close-btn"
                    onClick={closeModal}>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/modal-close.svg`}
                      />
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <span visible={!!nameError} className="error-text-small">
                    {nameError}
                  </span>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setBuildNameAndCheckValidity(e)}
                    className="col-lg-12 py-3 mt-4 w-full border border-gray-300"
                    placeholder="Enter Build Name"
                  />
                </div>
              </div>
              <div className="modal-footer">
                {localStorage.getItem("build").toLowerCase() &&
                localStorage.getItem("build").toLowerCase() ===
                  name.toLowerCase() ? (
                  <button
                    onClick={handleSaveBuild}
                    type="button"
                    className="btn-primary-modal"
                    disabled={disableSave}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-secondary-modal"
                    onClick={handleSaveNewBuild}
                    disabled={disableSave}
                  >
                    SAVE AS NEW
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  );
}
