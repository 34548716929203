import { API_PATH } from "../constants";
import { fetchWithToken } from "../Fetcher";

const apiPath = API_PATH;
export const saveNewBuild = async (name, projectName) => {
    try {
      //yahan
      const build = JSON.parse(localStorage.getItem('products'));
      const selectedCats = JSON.parse(sessionStorage.getItem('selectedItems'));
      if (name) {
        build.name = name;

        const buildData = {};
        buildData.name = name;
        buildData.projectId = projectName;
        buildData.products = build;

        if(buildData.products.length > 0){
          buildData.products[0].selectedCats =  [...selectedCats];
        }

        console.log('builded', (buildData))
  
        const response = await fetchWithToken(`${apiPath}/api/projects-builds-new/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(buildData)
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        console.log(data);
    
        return data;
      }

     return false;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };


  export const updateBuild = async (name, projectName) => {
    try {
      //yahan
      const build = JSON.parse(localStorage.getItem('products'));
      const selectedCats = JSON.parse(sessionStorage.getItem('selectedItems'));
      if (name) {
        build.name = name;
        // build.selectedCats = [...selectedCats];
        const buildData = {};
        buildData.name = name;
        buildData.projectId = projectName;
        buildData.products = build;
        if(buildData.products.length > 0){
          buildData.products[0].selectedCats =  [...selectedCats];
        }
        

        console.log('builded', buildData)
  
        const response = await fetchWithToken(`${apiPath}/api/projects-builds/`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(buildData)
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        console.log(data);
    
        return data;
      }

     return false;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };


  function convertToGroupedObject(data) {
    // Initialize an empty object to store the grouped items
    const groupedObject = {};
    const uniqueGroups = [];
  
    // Iterate through each item in the data array
    data.forEach(item => {
      // Extract id and name directly
      const { id, name, ...rest } = item;
  
      // Iterate through the keys (0, 1, 2, etc.) to group by category
      Object.keys(rest).forEach(key => {
        const category = rest[key].category;
  
        // Create the category key in groupedObject if it doesn't exist
        if (!groupedObject[category]) {
          groupedObject[category] = [];
        }

        if (uniqueGroups.includes(rest[key].name + category)) {
          rest[key].name = "";
        }else{
          uniqueGroups.push(rest[key].name + category);
        }
  
        // Push the item under the corresponding category
        groupedObject[category].push(rest[key]);
      });
  
      // Add id and name to groupedObject as-is
      // groupedObject.id = id;
      // groupedObject.name = name;
      delete groupedObject.id
      delete groupedObject.name
    });
  
    return groupedObject;
  }

  export const fetchBuildByName = async (name) => {
    try {
      const buildRes = await fetchWithToken(`${apiPath}/api/builds?name=${name}`);
  
      const build = await buildRes.json();
      console.log("buildstge", build);
      return build;
    } catch (error) {
      console.error("Error fetching data", error);
      return [];
    }
  };
  
  export const fetchBuildById = async (id) => {
    try {
      const buildRes = await fetchWithToken(`${apiPath}/api/builds/${id}`);
  
      const build = await buildRes.json();
  
      return build;
    } catch (error) {
      console.error("Error fetching data", error);
      return [];
    }
  };
  
  export const findBuildByName = async (name) => {
    try {
      const buildRes = await fetchWithToken(
        `${apiPath}/api/builds/find-build-by-name/?name=${name}`
      );
  
      const build = await buildRes.json();
  
      return build;
    } catch (error) {
      console.error("Error fetching data", error);
      return [];
    }
  };

  export const getAllBuilds = async () => {
    try {
      const buildRes = await 
        fetchWithToken(`${apiPath}/api/builds`);
  
      const build = await buildRes.json();
  
      return build;
    } catch (error) {
      console.error('Error fetching data', error);
      return [];
    }
  };

  export const deleteBuild = async (id) => {
    try {
      const buildRes = await 
        fetchWithToken(`${apiPath}/api/builds/${id}`, {method: "DELETE"});
  
      const build = await buildRes.json();
  
      return build;
    } catch (error) {
      console.error('Error fetching data', error);
      return [];
    }
  };

  export const cloneBuild = async (id) => {
    try {
      const buildRes = await 
        fetchWithToken(`${apiPath}/api/builds/${id}`, {method: "POST"});
  
      const build = await buildRes.json();
  
      return build;
    } catch (error) {
      console.error('Error fetching data', error);
      return [];
    }
  };
  