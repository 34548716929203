import React, { useEffect, useState } from 'react'
import UsersTable from '../components/UsersTable'
import CreateUserModal from '../components/CreateUserModal'
import ConfirmationDialog from '../components/shared/ConfirmationDialog'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addUser, deleteUser, editUser, getAll } from '../lib/api/UserHandler';
import { API_PATH } from '../lib/constants';
import { fetchWithToken } from '../lib/Fetcher';


export default function Users() {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [removeDialogIsOpen, setRemoveDialogIsOpen] = useState(false);

    const [users, setUsers] = useState([]);

    const [selectedUser, setSelectedUser] = useState({});

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => {setSelectedUser({}); setModalIsOpen(false)};

    const showRemoveDialog = () => setRemoveDialogIsOpen(true);
    const hideRemoveDialog = () => {setSelectedUser({}); setRemoveDialogIsOpen(false)};

    useEffect(() => {
        // const data = [
        //     { name: 'Admin', role: 'ADMIN', email: 'admin@bos.com', icon: 'HiOutlinePencil' },
        //     { name: 'Jane', role: 'TEAM_MEMBER', email: 'jane@example.com', icon: 'HiOutlinePencil' },
        //     { name: 'Allen', role: 'CUSTOMER', email: 'allen@example.com', icon: 'HiOutlinePencil' },
        //     // More data...
        //   ];
        const users  = getAll().then(data => {
            console.log('data', data)
            // const all = data.map(d => d.icon == 'HiOutlinePencil');
            setUsers(data);
        })

        // setUsers(data);
    }, [])


    const handleAddUser = async(newUser) => {
        const exists = users.some(user => user.email == newUser.email);
        if(!exists){
            // newUser.username = newUser.username;
            const res = await addUser(newUser);
            
            console.log('resss', res);
            if(res){
                console.log('res respon', res)
                if(res.error){
                    toast.error(res.error);
                    return;
                }
                setUsers((prevUsers) => [...prevUsers, newUser]);
            }
        }else{
            toast.error("User email already exists");
        }
    }

    const handleRemoveUser = () => {
       
        if(selectedUser && selectedUser.role != 'ADMIN'){
            const updatedUsers = users.filter(user => user.email !== selectedUser.email);
            if(deleteUser(selectedUser.email)){
                setUsers(updatedUsers);
            }else {
                toast.error('Error while removing user');
            }
        
            
        }else{
            toast.error('Administrators cannot be removed');
        }

        setSelectedUser(null);
        
        
    }

    const handleConfirmRemoveUser = (user) => {
       
        setSelectedUser(user);
        showRemoveDialog();
        
    }

    const handleEditUserModal = (user) => {
       
        console.log('use ', user)
        setSelectedUser(user);
        openModal();
    }

    const handleApproveUser = async (user) => {
        try {
            const { email, confirmationCode } = user;
            const response = await fetchWithToken(
            `${API_PATH}/confirm?email=${email}`
            );
            const data = await response.json();

            if (response.ok) {
                toast.success(data.message);
            } else {
                toast.error(data.error);
            }
        } catch (error) {
            console.error(error);
            toast.error("Some error occured, please try again later");
        }
    };

    const handleEditUser = (newUser) => {
        const res = editUser(newUser);
        if(res){
         const updatedUsers = users.map(user => {
            if (user.email === newUser.email) {
              return { ...user, ...newUser };
            }
            return user;
          });
        
            setUsers(updatedUsers);
        }
    }

	return (users && <div>
        <div className="flex justify-end">
		<button onClick={openModal} className="mt-4 mb-4 bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded">
  			Add User
		</button>
		</div>
        <CreateUserModal isOpen={modalIsOpen} onRequestClose={closeModal} onCreateRequest={handleAddUser} 
            onEditRequest={handleEditUser} userData={selectedUser}/>
        <UsersTable onRemoveUser={handleConfirmRemoveUser} onEditUser={handleEditUserModal} onApproveUser={handleApproveUser} data={users} />
        <ConfirmationDialog isOpen={removeDialogIsOpen} onRequestClose={hideRemoveDialog} 
            title="Are you sure?" message="Do you want to remove selected user?" onConfirm={handleRemoveUser} />
        <ToastContainer />
    </div>)
}