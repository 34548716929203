// CreateUserModal.js
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

// Ensure the modal is attached to the app root
Modal.setAppElement('#root');

const CreateUserModal = ({ isOpen, onRequestClose, onCreateRequest, onEditRequest, userData }) => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('ADMIN');


    useEffect(() => {
        
        setName(userData.username);
        setEmail(userData.email);
        setRole(userData.role ? userData.role : 'ADMIN');
        
    }, [userData])

    const handleCreateEditUser = (e) => {
        e.preventDefault();
    
        // Create user object
        const newUser = {
          username: name,
          email,
          password,
          role,
        };
    
        // Call the onCreateUser callback with the new user
        if(userData && userData.username){
            onEditRequest(newUser);
        }else{
            onCreateRequest(newUser)
        }
    
        // Reset form fields
        setName('');
        setEmail('');
        setPassword('');
        setRole('');
    
        // Close the modal
        onRequestClose();
      };
    

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel={userData && userData.username  ? "Edit User" : "Create New User" }
        className="max-w-2xl w-3/4 p-6 mx-auto my-16 bg-white rounded shadow-lg"
        overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
      >
        <div className="flex flex-col items-center">
          <h2 className="text-2xl w-full font-bold mb-4">{userData && userData.username  ? "Edit User" : "Create New User" }</h2>
          <form onSubmit={handleCreateEditUser} className="w-full">
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                  Username
                </label>
                <input
                  id="username"
                  value={name}
                  required
                  readOnly={userData?.username}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Username"
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  id="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  readOnly={userData?.username}
                  type="email"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                  Password
                </label>
                <input
                  required
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Password"
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
                  Role
                </label>
                <select
                  id="role"
                  required
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option key="1" value="ADMIN">Admin</option>
                  <option key="2" value="TEAM_MEMBER">Team Member</option>
                  <option key="3" value="CUSTOMER">Customer</option>
                </select>
              </div>
            </div>
            <div className="flex items-center justify-end">
              
              <button
                type="submit"
                className="bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {userData && userData.username  ? "Edit User" : "Create User" }
              </button>

              <button
                type="button"
                onClick={onRequestClose}
                className="bg-slate-400 hover:bg-slate-500 ml-1 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  };
  

export default CreateUserModal;
