import React, { useState } from "react";
import { forgotPassword } from "../lib/api/UserHandler";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      
      const response = await forgotPassword(email);

      if (response.ok) {
        setSuccess(
          "A password reset link has been sent to your email if it is registered."
        );
        setError("");
      } else {
        const data = await response.json();
        setError(data.message || "An error occurred. Please try again.");
        setSuccess("");
      }
    } catch (err) {
      setError("Error sending reset email. Please try again.");
      setSuccess("");
    }
  };


  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={process.env.PUBLIC_URL + "/assets/images/bos-logo.png"}
          alt="bos.com"
        />
        <h2 className="mt-4 text-center text-3xl font-extrabold text-gray-900">
          Forgot Your Password?
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Enter your email to receive a password reset link.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="w-full p-3 mt-1 border border-gray-300 rounded-lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>


            {error && (
              <p className="mt-2 text-sm text-red-600" id="error-message">
                {error}
              </p>
            )}

            {success && (
              <p className="mt-2 text-sm text-green-600" id="success-message">
                {success}
              </p>
            )}

            <div>
              <button
                type="submit"
                className="w-full bg-red-900 hover:bg-red-950 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Send Reset Link
              </button>
            </div>
          </form>

          <div className="mt-6 text-center">
            <a
              href="/login"
              className="font-medium text-slate-500 hover:text-slate-600"
            >
              Back to Login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
