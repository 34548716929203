import {
	HiPencilAlt,
	HiOutlineDatabase,
	HiDocumentDuplicate,
    HiOutlineUserGroup
} from 'react-icons/hi'

export const DASHBOARD_SIDEBAR_LINKS = [
	
	{
		key: 'users',
		label: 'Users',
		path: '/',
		role: ['ADMIN'],
		icon: <HiOutlineUserGroup />
	},
    {
		key: 'projects',
		label: 'Projects',
		path: '/projects',
		role: ['ADMIN', 'TEAM_MEMBER'],
		icon: <HiDocumentDuplicate />
	},
    {
		key: 'builds',
		label: 'Builds',
		path: '/builds',
		role: ['ADMIN', 'TEAM_MEMBER' ,'CUSTOMER'],
		icon: <HiOutlineDatabase />
	},
    {
		key: 'edit-builds',
		label: 'Edit Master Build',
		path: '/edit-builds',
		role: ['ADMIN'],
		icon: <HiPencilAlt />
	},
	
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
	
]

export const HOST_NAME =
  process.env.REACT_APP_HOST_NAME
    ? process.env.REACT_APP_HOST_NAME.replace(/\/$/, '')
    : `http://161.35.113.213`;

export const API_PATH =
  process.env.REACT_APP_API_PATH ?
    process.env.REACT_APP_API_PATH.replace(/\/$/, '')
    : `http://161.35.113.213:3001`;