import React, { useState, useEffect, useRef } from "react";
import Report from "./Report";
import generatePDF from "react-to-pdf";

export default function ReportDownloader() {
  const [html, setHtml] = useState("");
  const [buildName, setBuildName] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const targetRef = useRef();

  const options = {
    filename: `${buildName || "report"}.pdf`,
    resolution: 2, // Adjust resolution as needed
    page: {
      format: "A4",
      margin: 0,
      orientation: "portrait",
    },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  useEffect(() => {
    setHtml("go");
  }, []);

  useEffect(() => {
    if (localStorage.getItem("build")) {
      setBuildName(localStorage.getItem("build") || "");
    }
  }, []);

  const handlePDFDownload = () => {
    if (targetRef.current) {
      targetRef.current.className = "h2c"
      setIsDownloading(true);
      generatePDF(targetRef, options)
        .then(() => {setIsDownloading(false); console.log("PDF generated successfully!")})
        .catch((error) => console.error("PDF generation failed:", error));
    } else {
      console.error("Unable to get the target element.");
    }
  };

  return (
    html && (
      <>
        <div className="tab-wrap">
          <a
            href={`/home`}
            className="nav-item nav-link"
            id="nav-home-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            <img src={"/assets/images/specify.png"} alt="Specify" />
            Specify
          </a>
          <a
            className="nav-item nav-link"
            id="nav-profile-tab"
            data-toggle="tab"
            href={`/home/build`}
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            <img src={"/assets/images/build-icon.png"} alt="Build" />
            Build
          </a>
          <a
            className="nav-item nav-link active-tab"
            id="nav-contact-tab"
            data-toggle="tab"
            href="#nav-comments"
            role="tab"
            aria-controls="nav-contact"
            aria-selected="false"
            style={{ cursor: "default", pointerEvents: "none" }}
          >
            <img src={"/assets/images/download-icon.png"} alt="Report" /> Report
          </a>
        </div>
        <div className="w-full flex justify-end">
          <button
            className="mr-10 mb-10 mt-10 bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handlePDFDownload} // Trigger PDF generation
            disabled={isDownloading}
          >
            Download Report PDF {isDownloading ? '...' : ''}
          </button>
        </div>
        <div id="html-content" ref={targetRef}>
          <Report />
        </div>
      </>
    )
  );
}
