import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { HiOutlineDocument, HiOutlineTrash, HiDuplicate, HiPencil, HiOutlineClipboard } from "react-icons/hi";
import { copyToClipboard } from "../lib/helpers";
import { toast } from "react-toastify";
import { HOST_NAME } from "../lib/constants";

const columns = [
  { Header: "Name", accessor: "name" },
  // { Header: "Project", accessor: "project" },
  { Header: "User", accessor: "user.username" },
  { Header: "Project", accessor: "project.name" },
  { Header: "Date Created", accessor: "createdAt" },
  // { Header: "Created By", accessor: "user" },
  { Header: "Action", accessor: "icon" },
];

function BuildsTable({ data, onRemoveProject, onCloneProject, onEditBuild }) {
  const [pageSize, setPageSize] = useState(30); 
  const [pageIndex, setPageIndex] = useState(0);

  function Actions({ row }) {
    const isCustomer = JSON.parse(localStorage.getItem("user"));
    return (
      <div style={{whiteSpace: 'nowrap'}}>
        <div className="tooltip-container">
          <HiOutlineDocument
            onClick={() => {
              
              localStorage.setItem("build", row.name);
              window.open("/home/report", "_blank");
            }}
            className="cursor-pointer mr-3 inline-flex"
          />
          <div className="tooltip-text">Generate Report</div>
        </div>
        {isCustomer.role != "CUSTOMER" && (
          <div className="tooltip-container">
            <HiOutlineTrash
              onClick={() => onRemoveProject(row)}
              className="cursor-pointer mr-3 inline-flex"
            />
            <div className="tooltip-text">Delete Build</div>
          </div>
        )}
        <div className="tooltip-container">
          <HiDuplicate
            onClick={() => onCloneProject(row)}
            className="cursor-pointer mr-3 inline-flex"
          />
          <div className="tooltip-text">Clone Build</div>
        </div>
        <div className="tooltip-container">
          <HiPencil
            onClick={() => onEditBuild(row)}
            className="cursor-pointer inline-flex"
          />
          <div className="tooltip-text">Edit Build</div>
        </div>
      </div>
    );
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

    const pageCount = Math.ceil(rows.length / pageSize);
  const paginatedRows = rows.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

  function formateDate(cell) {
    const date = new Date(cell.value);
      // Extract date components
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      
      // Extract time components
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      
      // Format as "MM-DD-YYYY at HH:MM"
      return `${month}-${day}-${year} at ${hours}:${minutes}`;
  }

  const copyBuildLink = (buildName, projectId) => {
    copyToClipboard(`${HOST_NAME}/view-build/?buildName=${buildName}&projectId=${projectId}`)
      .then(() => {
        toast.success("Link copied to clipboard");
      })
      .catch((e) => {
        console.error("Error while copying link: ", e);
        toast.error("Failed to copy link to clipboard");
      });
  };

  return (
    <>
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key="1"
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {paginatedRows.map((row) => {
            prepareRow(row);
            return (
              <tr key={row.original.name} {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>
                      {cell.column.id === "name" ? (
                        <div className="d-flex">
                          <div className="tooltip-container pr-2">
                            <HiOutlineClipboard
                              onClick={() => copyBuildLink(row.original.name, row.original.projectId)}
                              className="cursor-pointer inline-flex"
                            />
                            <div className="tooltip-text">Copy Link</div>
                          </div>
                          <Link onClick={() => onEditBuild(row.original)}>
                            {cell.render("Cell")}
                          </Link>
                        </div>
                      ) : cell.column.id === "icon" ? (
                        <Actions row={row.original} />
                      ) : cell.column.id === "createdAt" ? (  // Add the new case here
                        formateDate(cell)
                      ) : (
                        cell.render("Cell")
                      )
                    }
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>


      <div className="flex justify-end mt-3">
      <div className="text-gray-700 mr-3 mt-1">
          Page {pageIndex + 1} of {pageCount}
        </div>
      <button onClick={() => setPageIndex(0)} disabled={pageIndex === 0 } className={`border border-gray-300 rounded px-3 py-1 ${pageIndex === 0 ? 'bg-red-800 text-white cursor-not-allowed' : 'bg-red-900 text-white hover:bg-red-950'}`}>{"<<"}</button>
<button onClick={() => setPageIndex(pageIndex - 1)} disabled={pageIndex === 0} className={`border border-gray-300 rounded px-3 py-1 ${pageIndex === 0 ? 'bg-red-800 text-white cursor-not-allowed' : 'bg-red-900 text-white hover:bg-red-950'}`}>{"<"}</button>
<button onClick={() => setPageIndex(pageIndex + 1)} disabled={pageIndex >= pageCount - 1} className={`border border-gray-300 rounded px-3 py-1 ${pageIndex >= pageCount - 1 ? 'bg-red-800 text-white cursor-not-allowed' : 'bg-red-900 text-white hover:bg-red-950'}`}>{">"}</button>
<button onClick={() => setPageIndex(pageCount - 1)} disabled={pageIndex >= pageCount - 1} className={`border border-gray-300 rounded px-3 py-1 ${pageIndex >= pageCount - 1 ? 'bg-red-800 text-white cursor-not-allowed' : 'bg-red-900 text-white hover:bg-red-950'}`}>{">>"}</button>
</div>

    </>
  );
}

export default BuildsTable;
