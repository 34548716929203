// CreateUserModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';

// Ensure the modal is attached to the app root
Modal.setAppElement('#root');

const ConfirmationDialog = ({ title, message, isOpen, onRequestClose, onConfirm }) => {
    
    const handleOnConfirm = (e) => {
      e.preventDefault();

      onConfirm();

      onRequestClose();
    }

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Confirmation"
        className="max-w-2xl w-3/4 p-6 mx-auto my-16 bg-white rounded shadow-lg"
        overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
      >
        <div className="flex flex-col items-center">
          <h2 className="text-2xl text-center w-full font-bold mb-4">{title}</h2>
          <p>{message}</p>
          <div className="mt-2 modal-actions">
          <button
                type="button"
                onClick={handleOnConfirm}
                className="bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Yes
              </button>

              <button
                type="button"
                onClick={onRequestClose}
                className="bg-slate-400 hover:bg-slate-500 ml-1 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                No
              </button>
          </div>
          
        </div>
      </Modal>
    );
  };
  

export default ConfirmationDialog;
