import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { HiOutlinePencil, HiOutlineTrash, HiEyeOff } from "react-icons/hi";
import ProductsTable from "./ProductsTable";
import LoadingSpinner from "../pages/LoadingSpiner";

function ProductGroupTable({ data, onEditProductGroup, columns }) {
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);

  function Actions({ row }) {
    return (
      <>
      <div className="tooltip-container">
      <HiOutlinePencil
          onClick={() => onEditProductGroup(row.row.original.name)}
          className="cursor-pointer ml-3 inline-flex"
        />
                <div className="tooltip-text">Edit Product Group</div>
              </div>
       
        {/* <HiOutlineTrash onClick={() => onRemoveUser(row)} className='cursor-pointer mr-3 inline-flex' /> */}
        {/* <HiEyeOff tooltip="Hide Category" className='cursor-pointer inline-flex' /> */}
      </>
    );
  }

  const handleCheckboxChange = (id) => {
    if (selectedCheckbox == id) {
      setSelectedCheckbox(null);
    } else {
      setSelectedCheckbox(id);
    }
  };

  function PopulateRow({ row }) {
    if (row.column.id === "icon") {
      return <Actions row={row} />;
    } else {
      return row.render("Cell");
    }
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  return (
    <>
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key="1"
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            console.log("Blud =>",row)
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{<PopulateRow row={cell} />}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* rows[0].values.name */}
      {rows.length > 0 && 
        <ProductsTable
        showProducts={rows[0].id}
        products={data.find((x) => x.name == rows[0].values.name)}
      />
      }

      {(!rows || rows.length ==0) &&
        <LoadingSpinner />
      }
      
    </>
  );
}

const Products = ({ showProducts, subdata }) => {
  const data = subdata?.products ? subdata.products : {};

  const headers = [
    { Header: "Name", accessor: "name" },
    { Header: "Sub Category", accessor: "subcategory" },
    { Header: "List Price", accessor: "listPrice" },
    { Header: "Unit Cost", accessor: "unitCost" },
    { Header: "Dedicated Head Count", accessor: "dedicatedHeadCount" },
    { Header: "Square Feet", accessor: "squareFootage" },

    { Header: "Action", accessor: "icon" },
  ];
  return showProducts ? (
    <div className="mt-3">
      <h1>Products: </h1>
      <ProductsTable data={data} columns={headers} />
    </div>
  ) : null;
};

export default ProductGroupTable;
