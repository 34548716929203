// lib/api.js
import { toast } from 'react-toastify';
import { fetchWithToken } from '../Fetcher';
import { API_PATH } from '../constants';

const apiPath = API_PATH;

export const fetchProjectsWithLink = async (link) => {
  try {
    const response = await fetchWithToken(`${apiPath}/api/projects/link/${link}`);
    return await response.json();
  } catch (error) {
    return error;
  }
}

export const fetchProjectById = async (id) => {
  try {
    const response = await fetchWithToken(`${apiPath}/api/projects/${id}`);
    return await response.json();
  } catch (error) {
    return error;
  }
}

export const verifyPassword = async (link,password) => {
  try {
    const response = await fetchWithToken(`${apiPath}/api/projects/link/verify/${link}?password=${password}`);
    return await response.json();
  } catch (error) {
    return error;
  }
}


export const fetchProjects = async () => {
  try{
    const response = await fetchWithToken(`${apiPath}/api/projects`);
    return await response.json();
    
  }catch(error) {
    return error;
  }
}

export const addProject = async ({name, margin, password}) => {
  try {
      
      const newProject = { name, margin, password };
      const createResponse = await fetchWithToken(`${apiPath}/api/projects`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify(newProject)
      });

      if (!createResponse.ok) {
          throw new Error(await createResponse.json()).error;
      }

      const createdProject = await createResponse.json();
      return createdProject;
  } catch (error) {
      return { error: error.message };
  }
};

export const cloneProject = async (id) => {
  try {
      
      
      const cloneResponse = await fetchWithToken(`${apiPath}/api/projects/${id}/clone`);

      if (!cloneResponse.ok) {
          throw new Error(await cloneResponse.json()).error;
      }

      const createdProject = await cloneResponse.json();
      if(createdProject.error){
        return { error: createdProject.error };
      }
      return createdProject;
  } catch (error) {
      return { error: error.message };
  }
};

export const deleteProject = async (id) => {
  try {
      
      
      const response = await fetchWithToken(`${apiPath}/api/projects/${id}`, {
        method: "DELETE",
          headers: {
              "Content-Type": "application/json"
          },
      });

      if (!response.ok) {
          throw new Error(await response.json()).error;
      }

      const delProject = await response.json();
      if(delProject.error){
        return { error: delProject.error };
      }
      return {message: 'success'};
  } catch (error) {
      return { error: error.message };
  }
};

export const editProject = async (id, {name, margin, password}) => {
  try {
      
      const newProject = { name, margin, password };
      console.log('password recvd ', JSON.stringify(newProject))
      const createResponse = await fetchWithToken(`${apiPath}/api/projects/${id}`, {
          method: "PUT",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify(newProject)
      });

      if (!createResponse.ok) {
          throw new Error(await createResponse.json()).error;
      }

      const createdProject = await createResponse.json();
      return createdProject;
  } catch (error) {
      return { error: error.message };
  }
};
  