
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames'

// Ensure the modal is attached to the app root
Modal.setAppElement('#root');

const EditProductGroupModal = ({ isOpen, onRequestClose, onEditRequest, productGroupName, }) => {

    const [name, setName] = useState('');
    

    useEffect(() => {
        
        setName(productGroupName);
        
        
    }, [productGroupName])

    const handleCreateEditCategory = (e) => {
        e.preventDefault();
    
        onEditRequest(name);
        
        // Reset form fields
        setName('');
       
        
    
        // Close the modal
        onRequestClose();
      };
    

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Edit Product Group"
        className="max-w-2xl w-3/4 p-6 mx-auto my-16 bg-white rounded shadow-lg"
        overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
      >
        <div className="flex flex-col items-center">
          <h2 className="text-2xl w-full font-bold mb-4">Edit Product Group</h2>
          <form onSubmit={handleCreateEditCategory} className="w-full">
            <div className='grid-cols-1 grid gap-4 mb-4 mr-8'>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Product Group Name"
                />
              </div>

              
            </div>
            
            <div className="flex items-center justify-end">
              
              <button
                type="submit"
                className="bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Edit Product Group
              </button>

              <button
                type="button"
                onClick={onRequestClose}
                className="bg-slate-400 hover:bg-slate-500 ml-1 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  };
  

export default EditProductGroupModal;
