import { API_PATH } from "../constants";
import { fetchWithToken } from "../Fetcher";

const apiPath = API_PATH;
export const login = async (username, password, recaptcha) => {
    try{
        const response = await fetch(`${apiPath}/signin?username=${username}&password=${password}&recaptcha=${recaptcha}`);
        const data = await response.json();
        if(data.token){
            return data;
        }else{
            return data;
        }
        
    }catch(error) {
        return error;
    }
}

export const resendEmail = async (username) => {
    try {
        
        const response = await fetch(
        `${apiPath}/api/resend-verification-email?username=${username}`
        );
        const data = await response.json();
  
        if (response.ok) {
          return data.message;
        } else {
          return data.error;
        }
        } catch (error) {
          return 'error';
        }
}


export const forgotPassword = async (email) => {
    try{
        const response = await fetch(`${apiPath}/forgotPassword?email=${email}`);
        return response;
        
    }catch(error) {
        return error;
    }
}

export const resetPassword = async (email, token, newPassword) => {
    try{
        const response = await fetch(`${apiPath}/resetPassword`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ emailReq: email, token, newPassword }),
          });
        return response;
        
    }catch(error) {
        return error;
    }
}

export const register = async (username, email, password) => {
    try {
        console.log('pass', password)
        const newUser = { username, email, password, role: 'CUSTOMER' };
        const createResponse = await fetch(`${apiPath}/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(newUser)
        });

        if (!createResponse.ok) {
            throw new Error(await createResponse.json()).error;
        }

        const createdUser = await createResponse.json();
        return createdUser;
    } catch (error) {
        return { error: error.message };
    }
};


export const addUser = async ({username, email, password, role}) => {
    try {
        console.log('pass', password)
        const newUser = { username, email, password, role };
        const createResponse = await fetchWithToken(`${apiPath}/users`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(newUser)
        });

        if (!createResponse.ok) {
            const errorResponse = await createResponse.json();
            return { error: errorResponse.error };
        }

        const createdUser = await createResponse.json();
        return createdUser;
    } catch (error) {
        return { error: error.message };
    }
};

export const editUser = async ({email, password, role}) => {
    try {
        
        const newUser = { password, role };
        const createResponse = await fetchWithToken(`${apiPath}/users/${email}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(newUser)
        });

        if (!createResponse.ok) {
            throw new Error(await createResponse.json()).error;
        }

        const createdUser = await createResponse.json();
        return createdUser;
    } catch (error) {
        return { error: error.message };
    }
};

export const deleteUser = async(email) => {
    try {
        const response = await fetchWithToken(`${apiPath}/users/${email}`, {method: "DELETE"});
        if (!response.ok) {
            return false;
        }
        return true;
    }catch (error) {
        return false;
    }
}

export const getAll = async () => {
    try{
        const response = await fetchWithToken(`${apiPath}/api/users`);
        return await response.json();
        
    }catch(error) {
        return error;
    }
}
