// lib/api.js
import { API_PATH } from "../constants";
import { fetchWithToken } from "../Fetcher";

const apiPath = API_PATH;
export const fetchCategoriesAndSubcategories = async (checkHidden = false) => {
  try {
    const [categoriesRes, subcategoriesRes] = await Promise.all([
      fetchWithToken(`${apiPath}/api/categories`),
      fetchWithToken(`${apiPath}/api/subcategories`),
    ]);

    const categories = await categoriesRes.json();
    const subcategories = await subcategoriesRes.json();
    let categoriesFilter = categories;

    if (checkHidden) {
      categoriesFilter = categories.filter((cat) => !cat.hidden);
    }

    // Merging categories and subcategories
    let mergedData = categoriesFilter.map((category) => ({
      ...category,
      subcategories: checkHidden
        ? subcategories.filter(
            (sub) => !sub.hidden && sub.categoryId == category.id
          )
        : subcategories.filter((sub) => sub.categoryId == category.id),
    }));
    if (checkHidden) {
      console.log("mer", mergedData);
      mergedData = mergedData.filter(
        (category) => category.subcategories.length > 0
      );
    }

    mergedData.sort((a, b) => a.position - b.position);

    // Sorting subcategories within each category based on position
    mergedData = mergedData.map((category) => ({
      ...category,
      subcategories: category.subcategories.sort(
        (a, b) => a.position - b.position
      ),
    }));

    console.log("mergedData", mergedData);
    return mergedData;
  } catch (error) {
    console.error("Error fetching data", error);
    return [];
  }
};

export const fetchCategoriesAndSubcategoriesBySubIds = async (
  subcategoryIds
) => {
  console.log("subids ", subcategoryIds);
  try {
    const [categoriesRes, subcategoriesRes] = await Promise.all([
      fetchWithToken(`${apiPath}/api/categories`),
      fetchWithToken(`${apiPath}/api/subcategories`),
    ]);

    const categories = await categoriesRes.json();
    const subcategories = await subcategoriesRes.json();

    // Merging categories and subcategories
    const filteredSubcategories = subcategories.filter(
      (sub) => subcategoryIds.includes(sub.id) && !sub.hidden
    );

    // Find the parent categories of these subcategories
    const filteredCategories = categories.filter(
      (cat) =>
        !cat.hidden &&
        filteredSubcategories.some((sub) => sub.categoryId === cat.id)
    );

    const mergedData = filteredCategories.map((category) => {
      const categorySubcategories = filteredSubcategories.filter(
        (sub) => sub.categoryId === category.id
      );
      return {
        ...category,
        subcategories: categorySubcategories,
      };
    });

    const finalData = mergedData.filter(
      (category) => category.subcategories.length > 0
    );

    console.log("subcat ", finalData);

    return finalData;
  } catch (error) {
    console.error("Error fetching data", error);
    return [];
  }
};

export async function getCategoryNameByProductGroup(productGroup) {
  try {
    // Step 1: Fetch the subcategory based on the product group name

    const encodedProductGroup = encodeURIComponent(productGroup);
    const subcategoryResponse = await fetch(
      `${apiPath}/subcategories?productGroup=${encodedProductGroup}`
    );
    const subcategories = await subcategoryResponse.json();
    if (subcategories.length === 0) {
      throw new Error("No subcategory found with the specified product group");
    }

    // Assuming the first subcategory is the one we need
    const subcategory = subcategories[0];
    const categoryId = subcategory.categoryId;

    // Step 2: Fetch the category using the category ID
    const categoryResponse = await fetch(
      `${apiPath}/categories?id=${categoryId}`
    );
    const categories = await categoryResponse.json();
    if (categories.length === 0) {
      throw new Error("No category found with the specified ID");
    }

    // Step 3: Return the category name
    const category = categories[0];
    return category.name;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}
export const fetchCategoriesAndSubcategoriesByPGnames = async (pgs) => {
  try {
    const [categoriesRes, subcategoriesRes] = await Promise.all([
      fetchWithToken(`${apiPath}/api/categories`),
      fetchWithToken(`${apiPath}/api/subcategories`),
    ]);

    const categories = await categoriesRes.json();
    const subcategories = await subcategoriesRes.json();

    // Merging categories and subcategories
    const filteredSubcategories = subcategories.filter(
      (sub) => pgs.includes(sub.productGroup) && !sub.hidden
    );

    // Find the parent categories of these subcategories
    const filteredCategories = categories.filter(
      (cat) =>
        !cat.hidden &&
        filteredSubcategories.some((sub) => sub.categoryId === cat.id)
    );

    const mergedData = filteredCategories.map((category) => {
      const categorySubcategories = filteredSubcategories.filter(
        (sub) => sub.categoryId === category.id
      );
      return {
        ...category,
        subcategories: categorySubcategories,
      };
    });

    const finalData = mergedData.filter(
      (category) => category.subcategories.length > 0
    );

    console.log("subcat ", finalData);

    return finalData;
  } catch (error) {
    console.error("Error fetching data", error);
    return [];
  }
};


export async function saveSubcategory(subcategory) {
  try {
    const { name, text, productGroup, categoryId, image } = subcategory;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("text", text);
    formData.append("productGroup", productGroup);
    formData.append("categoryId", categoryId);
    formData.append("image", image);

    const createResponse = await fetchWithToken(
      `${API_PATH}/api/subcategories`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!createResponse.ok) {
      throw new Error(await createResponse.json()).error;
    }

    const createdSubcategory = await createResponse.json();
    return createdSubcategory;
  } catch (error) {
    return error ? { error: error.message } : "Error occurred";
  }
}

export async function saveCategory(category) {
  try {
    const createResponse = await fetchWithToken(
      `${API_PATH}/api/categories`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(category),
      }
    );

    if (!createResponse.ok) {
      throw new Error(await createResponse.json()).error;
    }

    const createdCategory = await createResponse.json();
    return createdCategory;
  } catch (error) {
    return { error: error.message };
  }
}

export async function editCategory(id, category) {
  if (!id) {
    return;
  }
  try {
    const createResponse = await fetchWithToken(
      `${API_PATH}/api/categories/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: category }),
      }
    );

    if (!createResponse.ok) {
      throw new Error(await createResponse.json()).error;
    }

    const createdCategory = await createResponse.json();
    return createdCategory;
  } catch (error) {
    return { error: error.message };
  }
}

export async function deleteCategory(id, category) {
  if (!id) {
    return;
  }
  try {
    const createResponse = await fetchWithToken(
      `${API_PATH}/api/categories/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!createResponse.ok) {
      throw new Error(await createResponse.json()).error;
    }

    const createdCategory = await createResponse.json();
    return createdCategory;
  } catch (error) {
    return { error: error.message };
  }
}

export async function toggleCategory(id, category) {
  if (!id) {
    return;
  }
  try {
    const createResponse = await fetchWithToken(
      `${API_PATH}/api/categories/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!createResponse.ok) {
      throw new Error(await createResponse.json()).error;
    }

    const createdCategory = await createResponse.json();
    return createdCategory;
  } catch (error) {
    return { error: error.message };
  }
}

export async function updateCategoryPosition(id, position) {
  if (!id || position === undefined) {
    return { error: "Id and position are required" };
  }
  console.log(id,position," kk")
  try {
    const response = await fetchWithToken(
      `${API_PATH}/api/reorder/categories`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, position }), // Include id and position in the request body
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error updating category");
    }

    const updatedCategory = await response.json();
    return updatedCategory;
  } catch (error) {
    return { error: error.message };
  }
}

export async function updateSubcategoryPosition(id, position) {
  if (!id || position === undefined) {
    return { error: "Id and position are required" };
  }

  try {
    const response = await fetchWithToken(
      `${API_PATH}/api/reorder/subcategories`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, position }), // Include id and position in the request body
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error updating category");
    }

    const updatedCategory = await response.json();
    return updatedCategory;
  } catch (error) {
    return { error: error.message };
  }
}

export async function editSubCategory(id, category) {
  if (!id) {
    return;
  }
  try {
    const { name, text, productGroup, categoryId, image } = category;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("text", text);
    formData.append("productGroup", productGroup);
    formData.append("categoryId", categoryId);
    formData.append("image", image);
    const createResponse = await fetchWithToken(
      `${API_PATH}/api/subcategories/${id}`,
      {
        method: "PUT",

        body: formData,
      }
    );

    if (!createResponse.ok) {
      throw new Error(await createResponse.json()).error;
    }

    const createdCategory = await createResponse.json();
    return createdCategory;
  } catch (error) {
    return { error: error.message };
  }
}

export async function deleteSubCategory(id, category) {
  if (!id) {
    return;
  }
  try {
    const createResponse = await fetchWithToken(
      `${API_PATH}/api/subcategories/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!createResponse.ok) {
      throw new Error(await createResponse.json()).error;
    }

    const createdCategory = await createResponse.json();
    return createdCategory;
  } catch (error) {
    return { error: error.message };
  }
}

export async function toggleSubCategory(id, category) {
  if (!id) {
    return;
  }
  try {
    const createResponse = await fetchWithToken(
      `${API_PATH}/api/subcategories/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!createResponse.ok) {
      throw new Error(await createResponse.json()).error;
    }

    const createdCategory = await createResponse.json();
    return createdCategory;
  } catch (error) {
    return { error: error.message };
  }
}
