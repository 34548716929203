// CreateUserModal.js
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames'
import ImageUploader from './shared/ImageUploader';

// Ensure the modal is attached to the app root
Modal.setAppElement('#root');

const CreateCategoryModal = ({ isOpen, onRequestClose, onCreateRequest, onEditRequest, categoryData, 
      isSubCategory, productGroupData }) => {

    const [name, setName] = useState('');
    const [text, setText] = useState('');
    const [hidden, setHidden] = useState(false);
    // const [password, setPassword] = useState('');
    const [productGroup, setProductGroup] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const [error, setError] = useState('');

    useEffect(() => {
        
        setName(categoryData?.name);
        setText(categoryData?.text);
        setHidden(categoryData?.hidden);
        setProductGroup(categoryData?.productGroup || '');
        
    }, [categoryData])


    const handleCreateEditCategory = (e) => {
        e.preventDefault();
    
        // Create user object
        const newCategory = {
          name,
          text,
          hidden,
          oldName: categoryData?.name ? categoryData.name : '',
          image: selectedFile,
          
        };

        if(isSubCategory){
          if(!selectedFile) {
            setError("Please select a file");
            return;
          }
          console.log('setting product group ', productGroup)
          newCategory.productGroup = productGroup;
        }
    
        // Call the onCreateUser callback with the new user
        if(categoryData && categoryData.name){
            onEditRequest(newCategory);
        }else{
            onCreateRequest(newCategory)
        }
    
        // Reset form fields
        setName('');
        setHidden(false);
        
    
        // Close the modal
        onRequestClose();
      };
    

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel={categoryData && categoryData.name  ? "Edit Category" : "Add New Category" }
        className="max-w-2xl w-3/4 p-6 mx-auto my-16 bg-white rounded shadow-lg"
        overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
      >
        <div className="flex flex-col items-center">
          <h2 className="text-2xl w-full font-bold mb-4">{categoryData && categoryData.name  ? "Edit Category" : "Add New Category" }</h2>
          <form onSubmit={handleCreateEditCategory} className="w-full">
            <div className={classNames(
									!isSubCategory && 'grid-cols-1',
                  isSubCategory && 'grid-cols-2',
									'grid gap-4 mb-4 mr-8'
								)}>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  id="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Category Name"
                />
              </div>

              {isSubCategory && productGroupData && <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="product-group">
                  Product Group
                </label>
                <input required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                  value={productGroup} onChange={(e) => setProductGroup(e.target.value)} type='text' readonly={categoryData?.productGroup} /> 
                
                
              </div>}
              </div>
              {isSubCategory && 
              <div className='grid-cols-1 w-full pr-8 mb-3'>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="product-group">
                  Description
                </label>
                <textarea required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                  value={text} onChange={(e) => setText(e.target.value)}  /> 
                
                
              </div>
              </div>
              }

              {isSubCategory &&
                <>
                  <ImageUploader onImageChange={image => {setSelectedFile(image); setError('')}} imageUrl={categoryData?.imageUrl} />
                  {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                </>
              }
              
            
            
            <div className="flex items-center justify-end">
              
              <button
                type="submit"
                className="bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {categoryData && categoryData.name  ? "Edit Category" : "Add Category" }
              </button>

              <button
                type="button"
                onClick={onRequestClose}
                className="bg-slate-400 hover:bg-slate-500 ml-1 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  };
  

export default CreateCategoryModal;
