import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useModal } from '../hooks/useModal';
import Modal from 'react-modal';
import { API_PATH } from '../../lib/constants';

Modal.setAppElement('#root');

export default function SubcategoryDetailsModal({isOpen}) {
    const { isModalOpen, closeModal, modalData, modalType } = useModal();
    const [showModal, setShowModal] = useState(isModalOpen);

    useEffect(() => {isModalOpen && modalType == 'subcategory' ? setShowModal(true) : setShowModal(false)}, [isModalOpen])

    return (
      <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={true}
      // contentLabel={userData && userData.name  ? "Edit User" : "Create New User" }
      className="display-block modal-dialog modal-dialog-centered"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
    >
      <div
  // className={classNames(isModalOpen ? "visible" : "hide", "modal fade show")}
  // id="cat-modal"
  // tabIndex={-1}
  // role="dialog"
  // aria-labelledby="exampleModalLabel"
  // aria-modal="true"
  style={{ display: "block", paddingLeft: 0 }}
>
  <div className="" role="document">
    <div className="mx-auto">
      <div className="modal-content">
        <div className="modal-query-wizard-wrapper">
          <div className="d-flex justify-content-between align-items-center modal-header-wrap">
            <h1 className="theme-primary-color f-bold f-20">
              {modalData?.title}
            </h1>
            
            <button
              type="button"
              onClick={closeModal}
              className="close no-btn modal-close-btn"
              data-dismiss="modal"
            >
              <span>
                <img src={`${process.env.PUBLIC_URL}/assets/images/modal-close.svg`} />
              </span>
            </button>
          </div>
          <div className="modal-body">
            
            { modalData?.image && <img src={`${API_PATH}${modalData?.image}`} /> }
            <p className='text-gray-700 text-lg leading-relaxed'>{modalData?.text}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</Modal>
    )
}