import React, { useEffect, useState } from "react";
import { useTable, useSortBy } from "react-table";
import { DndProvider } from "react-dnd";
import { useDrag, useDrop } from "react-dnd";
import { HiOutlinePencil, HiOutlineTrash, HiEyeOff } from "react-icons/hi";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  updateCategoryPosition,
  updateSubcategoryPosition,
} from "../lib/api/CategoriesHandler";
import "./Tooltip.css"; // Import the CSS file where your tooltip styles are defined
import { toast } from "react-toastify";

function CategoriesTableDragable({
  data,
  onRemoveCategory,
  onEditCategory,
  onHideCategory,
  onEditSubCategory,
  onRemoveSubCategory,
  columns,
  catModal,
  parentCategory,
  isSub,
  setpgName,
}) {
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  function Actions({ row }) {
    return (
      <>
        <div className="tooltip-container">
          <HiOutlinePencil
            onClick={() => onEditCategory(row, parentCategory)}
            className="cursor-pointer mr-3 inline-flex"
          />
          <div className="tooltip-text">Edit Category</div>
        </div>

        <div className="tooltip-container">
          <HiOutlineTrash
            onClick={() => onRemoveCategory(row, parentCategory)}
            className="cursor-pointer mr-3 inline-flex"
          />
          <div className="tooltip-text">Remove Category</div>
        </div>

        <div className="tooltip-container">
          <HiEyeOff
            onClick={() => onHideCategory(row, parentCategory)}
            className="cursor-pointer mr-3 inline-flex"
          />
          <div className="tooltip-text">Hide Category</div>
        </div>
      </>
    );
  }

  const PopulateRow = ({
    
    rowId,
    index,
    cell,
    onEditCategory,
    onRemoveCategory,
    selectedCheckbox,
    handleCheckboxChange,
    parentCategory,
    onHideCategory,
  }) => {
    //   useEffect(() => {
    //     const fetchData = async () => {
    //       console.log("ping", rowId);
    //       try {
    //         if (!isSub) {
    //         await updateSubcategoryPosition(rowId, index);
    //         } else {
    //         await updateCategoryPosition(rowId, index);
    //         }
    //       } catch (error) {
    //         console.error("Error updating position:", error);
    //       }
    //     };

    //     fetchData();
    //   }, [index, isSub, rowId]);

    if (cell.column.id === "icon") {
      return (
        <Actions
          row={cell.row.original}
          onEditCategory={onEditCategory}
          onRemoveCategory={onRemoveCategory}
          parentCategory={parentCategory}
          onHideCategory={onHideCategory}
        />
      );
    } else if (cell.column.id === "select") {
      return (
        <input
          type="checkbox"
          checked={selectedCheckbox === cell.row.original.id}
          onChange={() => handleCheckboxChange(cell.row.original.id)}
        />
      );
    } else if (cell.column.id === "hidden") {
      return cell.value ? "Yes" : "No";
    } else if (cell.column.id === "position") {
      return index;
    } else {
      return cell.value.length > 60
        ? `${cell.value.substring(0, 60)}...`
        : cell.render("Cell");
    }
  };
  const DraggableRow = ({
    row,
    index,
    moveRow,
    onEditCategory,
    onRemoveCategory,
    selectedCheckbox,
    handleCheckboxChange,
    parentCategory,
    onHideCategory,
    ...rest
  }) => {
    const ref = React.useRef(null);

    console.log('drow', row)

    const [, drop] = useDrop({
      accept: "row",
      hover(item, monitor) {
        if (!ref.current) return;
        const dragIndex = item.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) return;

        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

        moveRow(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
      drop(item) {
        console.log('itemin', item.index)
        // Call reorder here with updated indices
        reorder(row.original.id, item.index);
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: "row",
      item: { type: "row", index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    return (
      <tr ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }} {...rest}>
        {row.cells.map((cell) => (
          <td {...cell.getCellProps()}>
            <PopulateRow
              
              rowId={row.original.id}
              index={index}
              cell={cell}
              onEditCategory={onEditCategory}
              onRemoveCategory={onRemoveCategory}
              selectedCheckbox={selectedCheckbox}
              handleCheckboxChange={handleCheckboxChange}
              parentCategory={parentCategory}
              onHideCategory={onHideCategory}
            />
          </td>
        ))}
      </tr>
    );
  };
  const moveRow = (dragIndex, hoverIndex) => {
    console.log("hoverIndex", hoverIndex);
    const updatedData = Array.from(tableData);
    const [movedItem] = updatedData.splice(dragIndex, 1);
    updatedData.splice(hoverIndex, 0, movedItem);
    setTableData(updatedData);
  };
  const [orderIndex, setOrderIndex] = useState(0);
  const handleCheckboxChange = (id) => {
    setSelectedCheckbox(id === selectedCheckbox ? null : id);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData }, useSortBy);

  const reorder = () => {
    try {
      rows.forEach(async (row) => {
        console.log("laba",row.index,   )
        if (!isSub) {
          await updateSubcategoryPosition(row.original.id, row.index);
        } else {
          await updateCategoryPosition(row.original.id, row.index);
        }
        
      });
      toast.info('successfully re-ordered the categories');
    } catch {toast.error('Error while re-ordering the categories');}
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              console.log("mull", rows);
              return (
                <DraggableRow
                  key={row.id}
                  index={index}
                  row={row}
                  moveRow={moveRow}
                  {...row.getRowProps()}
                  onEditCategory={onEditCategory}
                  onRemoveCategory={onRemoveCategory}
                  selectedCheckbox={selectedCheckbox}
                  handleCheckboxChange={() => {
                    handleCheckboxChange(row.original.id);
                  }}
                  parentCategory={parentCategory}
                  onHideCategory={onHideCategory}
                />
              );
            })}
          </tbody>
        </table>
        <div className="flex justify-end">
          <button className="mt-4 mb-4 bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded" onClick={reorder}>
            Reorder
          </button>
        </div>
      </DndProvider>

      <Subcategories
        catModalHanlder={catModal}
        showCategories={selectedCheckbox}
        subdata={data.find((x) => x.id == selectedCheckbox)}
        onEditSubCategory={onEditSubCategory}
        onRemoveSubCategory={onRemoveSubCategory}
        onHideCategory={onHideCategory}
        isSub={isSub}
        setpgName={setpgName}
      />
    </>
  );
}

const Subcategories = ({
  showCategories,
  subdata,
  catModalHanlder,
  onEditSubCategory,
  onRemoveSubCategory,
  onHideCategory,
  isSub,
  setpgName,
}) => {
  const data = subdata?.subcategories ? subdata.subcategories : [];

  const headers = [
    { Header: "Select", accessor: "select" },
    { Header: "Name", accessor: "name" },
    { Header: "Position", accessor: "position" },

    { Header: "Description", accessor: "text" },
    { Header: "Product Group", accessor: "productGroup" },
    { Header: "Hidden", accessor: "hidden" },
    { Header: "Action", accessor: "icon" },
  ];
  return showCategories ? (
    <>
      {isSub ? (
        <>
          <h1 className="mb-4 text-lg font-semibold text-gray-700 mt-4 text-gray-600">
            Subcategories:{" "}
          </h1>
          <div className="flex justify-end">
            <button
              onClick={() => catModalHanlder(subdata.name)}
              className="mt-4 mb-4 bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded">
              Add Sub-Category
            </button>
          </div>

          <CategoriesTableDragable
            onEditCategory={onEditSubCategory}
            onRemoveCategory={onRemoveSubCategory}
            onHideCategory={onHideCategory}
            data={data}
            columns={headers}
            parentCategory={subdata.name}
            isSub={false}
            setpgName={setpgName}
          />
        </>
      ) : (
        subdata && subdata.productGroup && setpgName(subdata.productGroup)
      )}
    </>
  ) : null;
};

export default CategoriesTableDragable;
